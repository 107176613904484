import { getApiPlanConfig } from "@Services/sites";

export const getPlanConfigAdapter = async () => {
  try {
    const response = await getApiPlanConfig();

    if (!response) {
      return {
        status: 500,
        data: null,
      };
    }

    return {
      status: 200,
      data: {
        maxSalebox: response.data.maxSalebox,
        maxWarehouse: response.data.maxWarehouse,
        maxEmployee: response.data.maxEmployee,
        maxLocal: response.data.maxLocal,
      },
    };
  } catch (error) {
    console.error("Error en getPlanConfigAdapter:", error);
    return {
      status: 500,
      data: null,
    };
  }
};
