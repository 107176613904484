export const defaultBillingDocuments = [
  {
    documentType: 1,
    serialNumber: "001",
    number: "1",
    active: 0,
    documentName: "Boleta de Venta",
    idLounge: 0,
    creditNoteDocumentId: null,
  },
  {
    documentType: 2,
    serialNumber: "001",
    number: "1",
    active: 0,
    documentName: "Factura",
    idLounge: 0,
    creditNoteDocumentId: null,
  },
  {
    documentType: 3,
    serialNumber: "001",
    number: "1",
    active: 0,
    documentName: "Nota de Crédito (B)",
    idLounge: 0,
    creditNoteDocumentId: 1,
  },
  {
    documentType: 4,
    serialNumber: "001",
    number: "1",
    active: 0,
    documentName: "Nota de Crédito (F)",
    idLounge: 0,
    creditNoteDocumentId: 2,
  },
  {
    documentType: 5,
    serialNumber: "001",
    number: "1",
    active: 1,
    documentName: "Nota de venta",
    idLounge: 0,
    creditNoteDocumentId: null,
  },
];

export const getDefaultBillingInfoAdapter = (backResponse) => {
  // Si no hay respuesta del backend, usar el mock por defecto
  const data = backResponse?.data || defaultBillingDocuments;

  return {
    ...backResponse,
    data: data.map((item) => ({
      tipoDocumento: item.documentType,
      serie: item.serialNumber,
      numero: item.number,
      activo: item.active,
      nombreDocumento: item.documentName,
      idLocal: item.idLounge,
      creditNoteDocumentId: item.creditNoteDocumentId,
    })),
  };
};
