/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import SiteInformation from "./views/siteInformation/siteInformation";
import SiteSchedule from "./views/siteSchedule/siteSchedule";
import SiteCashRegister from "./views/siteCashRegister/siteCashRegister";
import {
  HeaderNavigation,
  TabNavigation,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@Hooks/store";
import { useSitesAction } from "@Hooks/useSitesActions";
import { HeaderContext } from "@Hooks/useSitesHeaderContext";
import style from "./siteDetails.module.css";
import { LoadingPulseIcon } from "@Models/icons";
import { SHOW_LOUNGES_DETAILS_UUID } from "@Models/const/securityAccess";

const SiteDetailsPage = () => {
  const navigate = useNavigate();
  const { idSede } = useParams();

  const headerContext: any = useContext(HeaderContext);

  const sitesHeaderProps = {
    textBttn: headerContext.textBttn,
    handleClick: headerContext.clickFunction,
    isDisabled: headerContext.isDisabled,
    isHidden: headerContext.isHidden,
    isPrimary: true,
    isLoading: headerContext.isLoading,
  };

  const {
    useSetCurrentSite,
    useSetSchedules,
    useSetCashRegisters,
    useSetPlanConfig,
  } = useSitesAction();

  const [promiseStatus, setPromiseStatus] = useState({
    currentSite: { status: 0, data: null },
    schedules: { status: 0, data: null },
    cashRegisters: { status: 0, data: null },
    planConfig: { status: 0, data: null },
  });

  const currentSiteState = useAppSelector((state) => state.currentSite);
  const schedulesState = useAppSelector((state) => state.siteSchedules);
  const cashRegistersState = useAppSelector((state) => state.listCashRegisters);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!validateModuleAccess(SHOW_LOUNGES_DETAILS_UUID, false))
          return navigate("/");
        const currentSiteData = await useSetCurrentSite(Number(idSede));
        const schedulesData = await useSetSchedules(Number(idSede));
        const cashRegistersData = await useSetCashRegisters(Number(idSede));
        const planConfigData = await useSetPlanConfig();

        setPromiseStatus({
          currentSite: {
            status: currentSiteData.status,
            data: currentSiteData.data[0],
          },
          schedules: { status: schedulesData.status, data: schedulesData.data },
          cashRegisters: {
            status: cashRegistersData.status,
            data: cashRegistersData.data,
          },
          planConfig: {
            status: planConfigData.status,
            data: planConfigData.data,
          },
        });
      } catch (error) {
        setPromiseStatus({
          ...promiseStatus,
          currentSite: { status: 401, data: null },
          schedules: { status: 401, data: null },
          cashRegisters: { status: 401, data: null },
          planConfig: { status: 401, data: null },
        });
      }
    };

    fetchData();
  }, []);

  const headerProps = {
    title: currentSiteState ? currentSiteState.nameLounge : "Error",
    previousAction: () => navigate("/"),
    previousActionMovement: "back",
    buttonProps: sitesHeaderProps,
  };

  const ErrorView = ({ children }) => {
    return (
      <div className={style.errorViewContainer}>
        <h1 className={style.primaryErrorMsg}>{children}</h1>
        <h2 className={style.secondaryErrorMsg}>
          Por favor, intente más tarde.
        </h2>
      </div>
    );
  };

  const generateView = (name, status, data, Component) => {
    if (status === 200) {
      if (JSON.stringify(data).length > 2) {
        return <Component initialValues={data} />;
      } else {
        return <ErrorView>No pudimos obtener {name} de la sede.</ErrorView>;
      }
    } else {
      return <ErrorView>No pudimos obtener {name} de la sede.</ErrorView>;
    }
  };

  const navigationViews = [
    {
      name: "Información",
      component: generateView(
        "la información",
        promiseStatus.currentSite.status,
        currentSiteState,
        SiteInformation
      ),
      auth: {
        uuid: SHOW_LOUNGES_DETAILS_UUID,
      },
    },
    {
      name: "Horarios",
      component: generateView(
        "los horarios",
        promiseStatus.schedules.status,
        schedulesState,
        SiteSchedule
      ),
      auth: {
        uuid: SHOW_LOUNGES_DETAILS_UUID,
      },
    },
    {
      name: "Cajas",
      component: generateView(
        "las cajas",
        promiseStatus.cashRegisters.status,
        cashRegistersState,
        SiteCashRegister
      ),
      auth: {
        uuid: SHOW_LOUNGES_DETAILS_UUID,
      },
    },
  ];

  if (
    promiseStatus.currentSite.status === 0 ||
    promiseStatus.schedules.status === 0 ||
    promiseStatus.cashRegisters.status === 0
  ) {
    return (
      <div className={style.loadingContainer}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </div>
    );
  }

  return (
    <>
      <HeaderNavigation {...headerProps} />
      <TabNavigation views={navigationViews} />
    </>
  );
};

export default SiteDetailsPage;
