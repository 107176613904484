import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface PaymentMethod {
  idPago: number;
  idTipoPagoMaestro: number;
  tipodePago: string;
  descripcion: string;
  activo: number;
}

const initialState = [];

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    updatePaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      return action.payload;
    },
    deletePaymentMethod: (state, action: PayloadAction<number>) => {
      return state.filter((item) => item.idPago !== action.payload);
    },
    addPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.push(action.payload);
    },
    modifyPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      return state.map((item) => {
        if (item.idPago === action.payload.idPago) {
          return action.payload;
        }
        return item;
      });
    },
  },
});

export default paymentMethodsSlice.reducer;
export const {
  updatePaymentMethods,
  deletePaymentMethod,
  addPaymentMethod,
  modifyPaymentMethod,
} = paymentMethodsSlice.actions;
