import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SiteHomeButton from "../../atoms/siteHomeButton/SiteHomeButton";
import style from "./sitesMenuContainer.module.css";
import { HeaderContext } from "@Hooks/useSitesHeaderContext";
import { CashRegister } from "@ReduxService/states/cashRegisterSlice";
import {
  MODIFY_LOUNGES_UUID,
  SHOW_LOUNGES_DETAILS_UUID,
} from "@Models/const/securityAccess";
//@ts-ignore
import { validateModuleAccess } from "@viuti/recursos";

const SitesMenuContainer = ({ items, type = "site" }) => {
  const { idSede } = useParams();
  const navigate = useNavigate();
  const headerContext = useContext(HeaderContext);

  const handleClickSite = (id) => {
    if (!validateModuleAccess(SHOW_LOUNGES_DETAILS_UUID)) return navigate("/");
    return navigate(`/${id}`);
  };

  const handleClickCashRegister = (id) => {
    return navigate(`/${idSede}`, {
      state: { cashregister: id, viewAction: "edit" },
    });
  };

  const siteButtonProps = (item) => {
    return {
      key: item.local,
      label: item.nombre,
      onClick: () => handleClickSite(item.local),
      hasMoreOptions: false,
    };
  };

  const cashRegisterButtonProps = (item: CashRegister) => {
    return {
      key: item.idSaleBox,
      cashId: item.idSaleBox,
      label: item.saleBoxName,
      onClick: () => handleClickCashRegister(item.idSaleBox),
      isActive: item.active === 1,
      isOpen: item.open === 1,
      isDowngraded: item.isDowngraded,
      hasMoreOptions: validateModuleAccess(MODIFY_LOUNGES_UUID, false),
    };
  };

  useEffect(() => {
    if (type === "site") {
      //@ts-ignore
      headerContext.handleIsHidden(false);
    } else {
      //@ts-ignore
      headerContext.handleIsHidden(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={style.sitesMenuContainer}>
      {items.map((item, index) => (
        //@ts-ignore
        <SiteHomeButton
          {...(type === "site"
            ? siteButtonProps(item)
            : cashRegisterButtonProps(item))}
          key={item.idSaleBox}
          type={type}
          index={index}
        />
      ))}
    </div>
  );
};

export default SitesMenuContainer;
