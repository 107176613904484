// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Czt_NtzqMOBsHpm5Wlv3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.eHjT6YBgt00CDdI9MXtW {
  color: #252525;
  font-family: "Mulish";
  margin-top: 8px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.Hiiso8_EJjjEkF9LDqxJ {
  display: flex;
  gap: 16px;
  width: 100%;
}

.DY34XzDPjZSu2rC4jzsX {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.BWeqEwrrkKxQEHh0koxM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  animation: XXT44WqAjfFKlKWnaq9w 0.4s ease-in-out;
}

@keyframes XXT44WqAjfFKlKWnaq9w {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/paymentMethods/paymentMethods.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,SAAS;EACT,gDAAmC;AACrC;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".loadingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.paymentMethod__errorMessage {\n  color: #252525;\n  font-family: \"Mulish\";\n  margin-top: 8px;\n  text-align: center;\n  font-size: 1.2rem;\n  font-weight: bold;\n}\n\n.paymentMethod__container {\n  display: flex;\n  gap: 16px;\n  width: 100%;\n}\n\n.paymentMethod__container_section {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  gap: 16px;\n}\n\n.paymentMethod__container_subsection {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  gap: 16px;\n  animation: estirar 0.4s ease-in-out;\n}\n\n@keyframes estirar {\n  from {\n    width: 0;\n  }\n  to {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `Czt_NtzqMOBsHpm5Wlv3`,
	"paymentMethod__errorMessage": `eHjT6YBgt00CDdI9MXtW`,
	"paymentMethod__container": `Hiiso8_EJjjEkF9LDqxJ`,
	"paymentMethod__container_section": `DY34XzDPjZSu2rC4jzsX`,
	"paymentMethod__container_subsection": `BWeqEwrrkKxQEHh0koxM`,
	"estirar": `XXT44WqAjfFKlKWnaq9w`
};
export default ___CSS_LOADER_EXPORT___;
