import axios, { isAxiosError } from "axios";
import { apiDomain, getConfig } from "../api";

export const deleteCashService = async (fetchBody) => {
  const DELETE_URL = `${apiDomain}/caja/v1/saleBox?idSaleBox=${fetchBody.cashId}`;

  const fetchConfig = getConfig();

  try {
    await axios.delete(DELETE_URL, fetchConfig);
    return {
      isSuccess: true,
      successMessage: "La caja fue eliminada con éxito.",
    };
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage:
        err.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
    };
  }
};
