import { onActiveLocal } from "../../../../../../adapters/onActiveLocal";
import { onDeleteLocal } from "../../../../../../adapters/onDeleteLocal";
import { onInactiveLocal } from "../../../../../../adapters/onInactiveLocal";

export const OPTIONS = (
  cashId: number,
  dispatch,
  setIsDeleting,
  setIsDeactivating,
  cashRegisters,
  isCashActive = true
) => [
  {
    itemLabel: isCashActive ? "Desactivar" : "Activar",
    itemLabelStyle: { color: "#1a0063" },
    onItemSelected: isCashActive
      ? () =>
          onInactiveLocal(cashId, dispatch, cashRegisters, setIsDeactivating)
      : () => onActiveLocal(cashId, dispatch, setIsDeactivating),
  },
  {
    itemLabel: "Eliminar",
    itemLabelStyle: { color: "#BA1A1A" },
    onItemSelected: () =>
      onDeleteLocal(cashId, dispatch, setIsDeleting, cashRegisters),
  },
];
