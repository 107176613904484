import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface PlanConfig {
  maxSalebox: number;
  maxWarehouse: number;
  maxEmployee: number;
  maxLocal: number;
}

const DEFAULT_STATE: PlanConfig = {
  maxSalebox: 0,
  maxWarehouse: 0,
  maxEmployee: 0,
  maxLocal: 0,
};

const initialState: PlanConfig = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).planConfig : DEFAULT_STATE;
})();

export const planConfigSlice = createSlice({
  name: "planConfig",
  initialState,
  reducers: {
    updatePlanConfig: (state, action: PayloadAction<PlanConfig>) => {
      return action.payload;
    },
  },
});

export default planConfigSlice.reducer;
export const { updatePlanConfig } = planConfigSlice.actions;
