import { useAppSelector } from "@Hooks/store";
import { deleteCashRegister } from "@ReduxService/states/cashRegisterSlice";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { deleteCashService } from "@Services/deleteCashService/deleteCashService";

export const onDeleteLocal = async (
  cashId: number,
  dispatch,
  setIsDeleting,
  cashRegisters
) => {
  setIsDeleting(true);

  const body = {
    cashId: cashId,
  };

  //Review if is the last cashRegister
  const isLastCashRegister = cashRegisters.length === 1;
  if (isLastCashRegister) {
    dispatch(
      updateNotificationMessage(
        "Lo siento, es necesario que permanezca al menos una caja."
      )
    );
    dispatch(updateNotificationStatus(400));
    setIsDeleting(false);
    return;
  }

  // If not, so send the delete to the backend, and wait the response
  const backendResponse = await deleteCashService(body);

  if (backendResponse.isSuccess) {
    dispatch(deleteCashRegister(cashId));
    dispatch(updateNotificationMessage(backendResponse.successMessage));
    dispatch(updateNotificationStatus(200));
  } else {
    dispatch(updateNotificationMessage(backendResponse.errorMessage));
    dispatch(updateNotificationStatus(400));
  }

  setIsDeleting(false);
};
