import React from "react";
//@ts-ignore
import styled from "./handlePrint.module.css";
import { Watermark } from "@hirohe/react-watermark";

// The reference is need to react-to-print library
const GeneratePrintableHTML = (props) => {
  const { TITLE_NAME, BUSINESS, LOCAL, BILLING, CLIENT } = props.billingData;
  const { hasWathermark } = props;
  return (
    <section ref={props.reference} className={styled.printContainer}>
      <Watermark
        show={hasWathermark}
        text="DOCUMENTO NO VALIDO"
        opacity={0.5}
        rotate={-25}
        multiline={false}
      >
        <header>
          <h1>{TITLE_NAME}</h1>
          <p>{BUSINESS.NAME}</p>
          <p>{LOCAL.NAME}</p>
          <p>RUC {BUSINESS.RUC}</p>
          <p>{LOCAL.LOCATION}</p>
          <p>{LOCAL.EMAIL}</p>
          <p>Telf. {LOCAL.PHONE}</p>
          <p>F. Emision 00/00/00 00:00</p>
        </header>

        <article className={styled.billCode}>
          <p>BOLETA DE VENTA ELECTRÓNICA</p>
          <p>{BILLING.CODE}</p>
        </article>

        <article className={styled.clientInformation}>
          <p>PASAPORTE: {CLIENT.PASSPORT}</p>
          <p>Sr(s): {CLIENT.NAME}</p>
          <p>Direcc: {CLIENT.LOCATION}</p>
        </article>

        <tr className={styled.trHeader}>
          <td>DESCRIPCIÓN</td>
          <td>CANT</td>
          <td>TOTAL</td>
        </tr>
        <tr>
          <td>{BILLING.PRODUCTS[0].DESCRIPTION}</td>
          <td>{BILLING.PRODUCTS[0].AMOUNT}</td>
          <td>{BILLING.PRODUCTS[0].TOTAL}</td>
        </tr>

        <article className={styled.price__detail}>
          <p>OP. EXONERADAS S/.</p>
          <p>0.00.</p>
          <p>OP. GRAVADAS S/.</p>
          <p>203.39</p>
          <p>OP. IGV S/.</p>
          <p>36.61</p>
          <p>OP. ICBPER S/.</p>
          <p>0</p>
          <p>OP. TOTAL S/.</p>
          <p>240.00</p>
        </article>

        <article className={styled.billing__usedType}>
          <p className={styled.billingType}>VISA</p>
          <p>S/.</p>
          <p>240.00</p>
        </article>
        <p style={{ textAlign: "left", marginBottom: "20px" }}>
          Resp. Apellido, Nombre
        </p>

        <footer className={styled.billing__footer}>
          <p>ESTA ES UNA REPRESENTACIÓN IMPRESA DEL</p>
          <p>DOCUMENTO DE VENTA ELECTRONICO</p>
          {/* <strong>DL42EJDL2LWS0DFKDFSDFDF53JFDSA9</strong>
          <p>Para consultar este documento dirijase a:</p>
          <p>https://viutifyapp.com/efact.aspx</p> */}
          <strong>** Gracias por su visita **</strong>
        </footer>
      </Watermark>
    </section>
  );
};

export default GeneratePrintableHTML;
