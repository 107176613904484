// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TXwI4NJmze5Zth9OLprU {
  width: 100%;
  height: 100%;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  align-content: baseline;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/sitesMenuContainer/sitesMenuContainer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,aAAa;EACb,4DAA4D;EAC5D,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".sitesMenuContainer {\n  width: 100%;\n  height: 100%;\n  max-height: -webkit-fill-available;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  align-content: baseline;\n  gap: 16px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sitesMenuContainer": `TXwI4NJmze5Zth9OLprU`
};
export default ___CSS_LOADER_EXPORT___;
