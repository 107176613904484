import {
  getApiSiteData,
  getApiSchedules,
  getApiCashRegisters,
} from "@Services/sites";

export const getApiSiteDataAdapter = async (idSite) => {
  const response = await getApiSiteData(idSite);

  const formattedDataResponse = response.data.map((item) => {
    return {
      idlocal: item.idLounge,
      idempresa: item.idCompany,
      nombre: item.nameLounge,
      direccion: item.address,
      departamento: item.departament,
      sitioWeb: item.webSite,
      paisData: {
        idPais: item.dataCountry.idCountry,
        nombrePais: item.dataCountry.nameCountry,
      },
    };
  });

  return {
    ...response,
    data: formattedDataResponse,
  };
};

export const getApiSchedulesAdapter = async (idSite) => {
  const response = await getApiSchedules(idSite);

  return {
    ...response,
    data: response.data.map((item) => ({
      idcodigodia: item.idCodeDay,
      apertura: item.opening,
      cierre: item.closing,
      desactivado: item.desabled,
      idHorario: item.idShedule,
      idlocal: item.idLounge,
    })),
  };
};

export const getApiCashRegistersAdapter = async (idSite) => {
  const response = await getApiCashRegisters(idSite);
  return {
    ...response,
    data: response.data.map((item) => ({
      idSaleBox: item.idSaleBox,
      saleBoxName: item.saleBoxName,
      open: item.estado,
      active: item.visible === true ? 1 : 0,
      isDowngraded: Boolean(item.isDowngrade),
    })),
  };
};
