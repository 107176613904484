import React, { Dispatch } from "react";
import { TripleDotsIcon } from "../../../../../models/icons";
import styled from "./style/MoreOptions.module.css";
//@ts-ignore
import { SelectWrapper } from "@viuti/recursos";
import { OPTIONS } from "./consts/selectItems";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@Hooks/store";

interface IMoreOptionsProps {
  cashId: number;
  setIsDeleting: Dispatch<boolean>;
  setIsDeactivating: Dispatch<boolean>;
  isCashActive: boolean;
}

const MoreOptions: React.SFC<IMoreOptionsProps> = (props) => {
  const dispatch = useDispatch();
  const cashRegisters = useAppSelector((state) => state.listCashRegisters);

  const childButton = (e) => {
    e.stopPropagation();
  };

  return (
    <button onClick={childButton} className={styled.moreOptions__button}>
      <SelectWrapper
        selectItems={OPTIONS(
          props.cashId,
          dispatch,
          props.setIsDeleting,
          props.setIsDeactivating,
          cashRegisters,
          props.isCashActive
        )}
      >
        <img
          src={TripleDotsIcon}
          alt="triple dot icon"
          className={styled.moreOptions__img}
        />
      </SelectWrapper>
    </button>
  );
};

export default MoreOptions;
