// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dzljnKHwauMR0mnThQMC,
.TdZJfJD3l1_d2HwubBf4 {
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #937cf4;
  color: #937cf4;
  padding: 20px;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 19.8px */
  background-color: transparent;
}

.dzljnKHwauMR0mnThQMC:hover {
  background: #947cf41f;
  cursor: pointer;
}

.TdZJfJD3l1_d2HwubBf4 {
  border-radius: 8px;
  background: #947cf44a;
  /* border: none; */
}

.EACQeNJfszaKqCEQcr8l,
.RIDX5j2Fw_uUxZuMphZV,
.IZ8LcmeKK37BtjYTsI2b {
  width: 36px;
  height: 36px;
  padding: 10px;
  border-radius: 15px;
  background-color: #dad4f2;
}

.EACQeNJfszaKqCEQcr8l {
  transform: rotate(180deg);
}

.RIDX5j2Fw_uUxZuMphZV {
  transform: rotate(0deg);
  background-color: #c3b6f5;
  animation: HCq61HZ04GwE_Opee0vM 0.3s ease-in-out;
}

@keyframes HCq61HZ04GwE_Opee0vM {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/paymentMethods/components/categoryMethod/categoryMethod.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;EAC9B,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;;;EAGE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gDAA6C;AAC/C;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".categoryMethod__container,\n.categoryMethod__container_active {\n  height: 66px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 8px;\n  border: 1px solid #937cf4;\n  color: #937cf4;\n  padding: 20px;\n  font-family: Mulish;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 110%; /* 19.8px */\n  background-color: transparent;\n}\n\n.categoryMethod__container:hover {\n  background: #947cf41f;\n  cursor: pointer;\n}\n\n.categoryMethod__container_active {\n  border-radius: 8px;\n  background: #947cf44a;\n  /* border: none; */\n}\n\n.categoryIcon__rotateRight,\n.categoryIcon__rotateLeft,\n.icon {\n  width: 36px;\n  height: 36px;\n  padding: 10px;\n  border-radius: 15px;\n  background-color: #dad4f2;\n}\n\n.categoryIcon__rotateRight {\n  transform: rotate(180deg);\n}\n\n.categoryIcon__rotateLeft {\n  transform: rotate(0deg);\n  background-color: #c3b6f5;\n  animation: transformRotation 0.3s ease-in-out;\n}\n\n@keyframes transformRotation {\n  from {\n    transform: rotate(180deg);\n  }\n  to {\n    transform: rotate(0deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryMethod__container": `dzljnKHwauMR0mnThQMC`,
	"categoryMethod__container_active": `TdZJfJD3l1_d2HwubBf4`,
	"categoryIcon__rotateRight": `EACQeNJfszaKqCEQcr8l`,
	"categoryIcon__rotateLeft": `RIDX5j2Fw_uUxZuMphZV`,
	"icon": `IZ8LcmeKK37BtjYTsI2b`,
	"transformRotation": `HCq61HZ04GwE_Opee0vM`
};
export default ___CSS_LOADER_EXPORT___;
