// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vK6VMMsUALE17o3jubEa {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.Fqvcsbu_ihHughnfrOoM {
  transform: rotate(90deg);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 8px;

  &:hover {
    background-color: #947cf44a;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/siteHomeButton/components/MoreOptions/style/MoreOptions.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;;EAEZ;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".moreOptions__button {\n  background-color: transparent;\n  border: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.moreOptions__img {\n  transform: rotate(90deg);\n  width: 36px;\n  height: 36px;\n  border-radius: 50%;\n  padding: 8px;\n\n  &:hover {\n    background-color: #947cf44a;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreOptions__button": `vK6VMMsUALE17o3jubEa`,
	"moreOptions__img": `Fqvcsbu_ihHughnfrOoM`
};
export default ___CSS_LOADER_EXPORT___;
