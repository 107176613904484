import React, { useEffect, useState } from "react";

// Components
import {
  Icon,
  TextInput,
  SelectInput,
  ButtonWithoutIcon,
  // @ts-ignore
} from "@viuti/recursos";
import { TimesIcon } from "@Models/icons";
import LoadingScreen from "@Components/atoms/LoadingScreen/LoadingScreen";

// Style
import style from "./movementsConceptsModal.module.css";

// Utilities
import { handleFieldBlur, handleFieldChange } from "@Utilities/FormHandler";

const MovementConceptModal = ({ data, controller, confirmAction }) => {
  const { visible, setVisible } = controller;
  const [localChanges, setLocalChanges] = useState({
    descripcion: "",
    tipomovimiento: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [errors, setErrors] = useState({
    descripcion: "",
    tipomovimiento: "",
  });

  const [touched, setTouched] = useState({
    descripcion: false,
    tipomovimiento: false,
  });

  const validationSchema = (e: { target: { name: string; value: any } }) => {
    const { name, value } = e.target;
    // declare a regex to match symbols
    const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"·¿\\|<>\/?]+/;
    switch (name) {
      case "descripcion":
        if (value === "") {
          errors.descripcion = "Campo requerido";
        } else if (value.match(symbolRegex)) {
          errors.descripcion = "No se permiten caracteres especiales";
        } else if (value.length > 40) {
          errors.descripcion = "El nombre no puede superar los 40 caracteres";
        } else {
          errors.descripcion = "";
        }
        break;
      case "tipomovimiento":
        if (value === "") {
          errors.tipomovimiento = "Campo requerido";
        } else {
          errors.tipomovimiento = "";
        }
        break;
      default:
        break;
    }

    return true;
  };

  const localHandleFieldChange = (e: {
    target: { name: string; value: any };
  }) => {
    handleFieldChange(e, setLocalChanges);
    handleFieldBlur(e, setTouched, () => validationSchema(e));
    validationSchema(e);
  };

  const [movementsTypeList] = useState([
    {
      id: 1,
      nombre: "Ingreso",
    },
    {
      id: 2,
      nombre: "Egreso",
    },
  ]);
  const movementsTypeListNames = movementsTypeList.map(
    (movement) => movement.nombre
  );

  const resetLocalChanges = () => {
    setLocalChanges({
      descripcion: "",
      tipomovimiento: "",
    });
    setTouched({
      descripcion: false,
      tipomovimiento: false,
    });
    setErrors({
      descripcion: "",
      tipomovimiento: "",
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const movement = {
      descripcion: localChanges.descripcion,
      tipomovimiento: localChanges.tipomovimiento === "Ingreso" ? 1 : 2,
      idcajamovimientoconcepto:
        data !== null ? data.idcajamovimientoconcepto : null,
    };

    const response = await confirmAction(movement);
    setIsLoading(false);

    if (response === 200) {
      data === null && resetLocalChanges();
    }
  };

  useEffect(() => {
    const initialValues =
      data !== null
        ? {
            descripcion: data.descripcion,
            tipomovimiento: data.tipomovimiento === 1 ? "Ingreso" : "Egreso",
          }
        : { descripcion: "", tipomovimiento: "" };
    setTouched((prev) => {
      return {
        ...prev,
        descripcion: data !== null,
        tipomovimiento: data !== null,
      };
    });
    setLocalChanges(initialValues);
  }, [data]);

  useEffect(() => {
    const hasErrors = Object.values(errors).some((error) => error !== "");
    const allTouched = Object.values(touched).every(
      (touched) => touched === true
    );
    if (allTouched && !hasErrors) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [localChanges, errors, touched]);

  return (
    <div
      className={`${style.layoutContainer} ${
        visible ? style.visible : style.hidden
      }`}
    >
      <div className={style.modalContainer}>
        <div className={style.modalHeader}>
          <p className={style.modalTitle}>
            {data === null
              ? "Agregar concepto de movimiento"
              : "Modificar concepto de movimiento"}
          </p>
          <button
            className={style.closeButtonContainer}
            onClick={() => {
              setVisible(false);
              resetLocalChanges();
            }}
          >
            <Icon path={TimesIcon} color={"#B4B4B4"} size={14} />
          </button>
        </div>
        <div className={style.selectContainer}>
          <SelectInput
            label={"Tipo de concepto"}
            error={errors.tipomovimiento}
            touched={touched.tipomovimiento}
            value={localChanges?.tipomovimiento}
            handleChange={localHandleFieldChange}
            name={"tipomovimiento"}
            flex={1}
            items={movementsTypeListNames}
          />
        </div>
        <div className={style.selectContainer}>
          <TextInput
            label="Nombre"
            value={localChanges?.descripcion}
            error={errors.descripcion}
            touched={touched.descripcion}
            handleChange={localHandleFieldChange}
            placeholder={"Escriba aquí..."}
            keyboardType={"text"}
            name={"descripcion"}
            flex={1}
          />
        </div>
        <div className={style.footerContainer}>
          <ButtonWithoutIcon
            isPrimary={true}
            textBttn={"Guardar"}
            handleClick={handleSubmit}
            isDisabled={isButtonDisabled}
          />
        </div>
        <LoadingScreen visible={isLoading} loaderWidth={150} />
      </div>
    </div>
  );
};

export default MovementConceptModal;
