import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface MovementType {
  idcajamovimientoconcepto: number;
  tipomovimiento: number;
  descripcion: string;
  bitactivo: number;
}

const initialState = [];

export const movementTypesSlice = createSlice({
  name: "movementTypes",
  initialState,
  reducers: {
    updateMovementTypes: (state, action: PayloadAction<MovementType[]>) => {
      return action.payload;
    },
    deleteMovementType: (state, action: PayloadAction<number>) => {
      return state.filter(
        (item) => item.idcajamovimientoconcepto !== action.payload
      );
    },
    modifyMovementType: (state, action: PayloadAction<MovementType>) => {
      const { idcajamovimientoconcepto } = action.payload;

      const newState = state.map((item) => {
        if (item.idcajamovimientoconcepto === idcajamovimientoconcepto) {
          return action.payload;
        }
        return item;
      });

      return newState;
    },
    addMovementType: (state, action: PayloadAction<MovementType>) => {
      state.unshift(action.payload);
    },
  },
});

export default movementTypesSlice.reducer;
export const {
  updateMovementTypes,
  deleteMovementType,
  addMovementType,
  modifyMovementType,
} = movementTypesSlice.actions;
