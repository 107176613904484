export const handleBillingSerieChange = (event, setSerie) => {
  //get the name and value of the event

  const { value } = event.target;

  const MAX_CHARACTER = 3;
  if (value.length > MAX_CHARACTER) return;

  const ONLY_NUMBER_REGUEX = /^[0-9]+$/;

  if (!ONLY_NUMBER_REGUEX.test(value) && value !== "") return;

  setSerie(value);
};
