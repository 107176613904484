import React from "react";
//@ts-ignore
import { SwitchInput, SelectInput } from "@viuti/recursos";
import style from "./ScheduleRow.module.css";
import { AddIcon, MinusIcon } from "@Models/icons";
import { convertIndexToTime } from "./functions/formatSchedule";

const ScheduleRow = ({
  schedule,
  index,
  editedFormValues,
  setEditedFormValues,
  disabled = false,
}) => {
  const dictionary = [
    {
      id: 1,
      dia: "Domingo",
    },
    {
      id: 2,
      dia: "Lunes",
    },
    {
      id: 3,
      dia: "Martes",
    },
    {
      id: 4,
      dia: "Miércoles",
    },
    {
      id: 5,
      dia: "Jueves",
    },
    {
      id: 6,
      dia: "Viernes",
    },
    {
      id: 7,
      dia: "Sábado",
    },
  ];

  const scheduleFormat = (schedule) => {
    const day = dictionary.find((item) => item.id === schedule.idcodigodia);
    return {
      dia: day.dia,
      apertura: schedule.apertura,
      cierre: schedule.cierre,
      desactivado: schedule.desactivado,
      tempIdHorario: schedule.tempIdHorario,
    };
  };

  const formatSchedule = scheduleFormat(schedule);

  const desactivado = formatSchedule.desactivado;

  const handleLocalChange = (e) => {
    let hour = e.target.value.split(":")[0];
    const minutes = e.target.value.split(":")[1].split(" ")[0];
    const period = e.target.value.split(":")[1].split(" ")[1];

    if (period === "PM") {
      hour = hour !== "12" ? Number(hour) + 12 : Number(hour);
    } else {
      hour = Number(hour) === 12 ? 0 : hour;
    }

    const formatedHour = `${hour}:${minutes}:00`;

    const daySchedules = editedFormValues.filter(
      (item) => item.idcodigodia === schedule.idcodigodia
    );
    const currentIndex = editedFormValues.indexOf(daySchedules[index]);

    if (
      e.target.name === "apertura" &&
      (formatedHour > editedFormValues[currentIndex].cierre ||
        editedFormValues[currentIndex].cierre === "")
    ) {
      let newEditedFormValues = [...editedFormValues];
      newEditedFormValues[currentIndex] = {
        ...newEditedFormValues[currentIndex],
        [e.target.name]: formatedHour,
        cierre: formatedHour,
      };

      setEditedFormValues(newEditedFormValues);
    } else {
      let newEditedFormValues = [...editedFormValues];
      newEditedFormValues[currentIndex] = {
        ...newEditedFormValues[currentIndex],
        [e.target.name]: formatedHour,
      };

      setEditedFormValues(newEditedFormValues);
    }
  };

  const handleSwitchChange = (e) => {
    const daySchedules = editedFormValues.filter(
      (item) => item.idcodigodia === schedule.idcodigodia
    );
    const currentIndex = editedFormValues.indexOf(daySchedules[index]);

    let newEditedFormValues = [...editedFormValues];

    newEditedFormValues[currentIndex] = {
      ...newEditedFormValues[currentIndex],
      desactivado: e ? 1 : 0,
    };

    setEditedFormValues(newEditedFormValues);
  };

  const formatTime = (time) => {
    if (time !== "") {
      const hour = time.split(":")[0];
      const formatHour = hour > 12 ? hour - 12 : hour;
      const formatHourWithZero = formatHour.toString().padStart(2, "0");

      const minutes = time.split(":")[1];
      const period = hour < 12 ? "AM" : "PM";
      return `${formatHourWithZero}:${minutes} ${period}`;
    } else {
      return "";
    }
  };

  const addNewSchedule = () => {
    const newSchedule = {
      idcodigodia: schedule.idcodigodia,
      apertura: "",
      cierre: "",
      desactivado: 1,
    };

    let newEditedFormValues = [...editedFormValues];
    newEditedFormValues.push(newSchedule);

    setEditedFormValues(newEditedFormValues);
  };

  const removeSchedule = () => {
    const daySchedules = editedFormValues.filter(
      (item) => item.idcodigodia === schedule.idcodigodia
    );
    const currentIndex = editedFormValues.indexOf(daySchedules[index]);

    let newEditedFormValues = [...editedFormValues];
    newEditedFormValues.splice(currentIndex, 1);

    setEditedFormValues(newEditedFormValues);
  };

  const getLastSchedule = () => {
    const daySchedules = editedFormValues.filter(
      (item) => item.idcodigodia === schedule.idcodigodia
    );

    if (index > 0) {
      const prevSchedule = daySchedules[index - 1];

      if (prevSchedule.cierre !== "") {
        const cierreDecimal =
          Number(prevSchedule.cierre.split(":")[0]) +
          Number(prevSchedule.cierre.split(":")[1]) / 60;
        return cierreDecimal;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const getLastCloseSchedule = () => {
    const daySchedules = editedFormValues.filter(
      (item) => item.idcodigodia === schedule.idcodigodia
    );

    const currentSchedule = daySchedules[index];

    if (currentSchedule.apertura !== "") {
      const aperturaDecimal =
        Number(currentSchedule.apertura.split(":")[0]) +
        Number(currentSchedule.apertura.split(":")[1]) / 60;
      const cierreDecimal =
        Number(currentSchedule.cierre.split(":")[0]) +
        Number(currentSchedule.cierre.split(":")[1]) / 60;

      if (
        cierreDecimal - aperturaDecimal > 0 ||
        currentSchedule.cierre === ""
      ) {
        return aperturaDecimal;
      } else {
        return cierreDecimal;
      }
    } else {
      return 0;
    }
  };

  const getSchedulesList = () => {
    let scheduleOptionsTemp = [];

    for (let index = getLastSchedule(); index < 24; index += 0.25) {
      const option = convertIndexToTime(index);
      scheduleOptionsTemp.push(option);
    }

    return scheduleOptionsTemp;
  };

  const getClosedSchedulesList = () => {
    let scheduleOptionsTemp = [];

    for (let index = getLastCloseSchedule(); index < 24; index += 0.25) {
      const option = convertIndexToTime(index);
      scheduleOptionsTemp.push(option);
    }

    return scheduleOptionsTemp;
  };

  const validateStartScheduleExists = (value) => {
    if (getSchedulesList().includes(value)) {
      return value;
    } else {
      return "";
    }
  };

  const validateCloseScheduleExists = (value) => {
    if (value !== "") {
      const valuePeriod = value.split(" ")[1];

      let valueDecimal =
        Number(value.split(":")[0]) +
        Number(value.split(":")[1].split(" ")[0]) / 60;
      valuePeriod === "PM" && valueDecimal < 12 && (valueDecimal += 12);

      if (validateStartScheduleExists(value)) {
        return value;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <div
      className={`${style.scheduleRowContainer} ${
        !desactivado ? style.scheduleRowContainerDisabled : ""
      }`}
    >
      <p className={style.scheduleDay}>{index === 0 && formatSchedule.dia}</p>
      <SwitchInput
        value={desactivado}
        handleChange={(e) => handleSwitchChange(e)}
        disabled={disabled}
      />
      <p className={style.scheduleLabel}>Desde</p>
      <div style={{ maxWidth: "120px" }}>
        <SelectInput
          value={validateStartScheduleExists(
            formatTime(formatSchedule.apertura)
          )}
          touched={true}
          error={""}
          handleBlur={() => {}}
          handleChange={handleLocalChange}
          name={"apertura"}
          items={getSchedulesList()}
          flex={1}
          disabled={!desactivado || disabled}
        />
      </div>
      <p className={style.scheduleLabel}>Hasta</p>
      <div style={{ maxWidth: "120px" }}>
        <SelectInput
          value={validateCloseScheduleExists(formatTime(formatSchedule.cierre))}
          touched
          error={""}
          handleBlur={() => {}}
          handleChange={handleLocalChange}
          name={"cierre"}
          items={getClosedSchedulesList()}
          flex={1}
          disabled={!desactivado || disabled}
        />
      </div>
      {index === 0 ? (
        <button
          className={`${style.addScheduleContainer} ${
            !desactivado || disabled ? style.addScheduleContainerDisabled : ""
          }`}
          onClick={addNewSchedule}
          disabled={!desactivado || disabled}
        >
          <img src={AddIcon} alt="" />
        </button>
      ) : (
        <button
          className={`${style.addScheduleContainer} ${
            !desactivado || disabled ? style.addScheduleContainerDisabled : ""
          }`}
          onClick={removeSchedule}
          disabled={!desactivado || disabled}
        >
          <img src={MinusIcon} alt="" />
        </button>
      )}
    </div>
  );
};

export default ScheduleRow;
