import styled from "./paymentMethods.module.css";
import { useEffect, useState } from "react";
import { CategoryMethod } from "./components/categoryMethod/CategoryMethod";
import { v4 as uuidv4 } from "uuid";
import { getPaymentMethods_adapter } from "../../adapters/paymentMethods/getPaymentMethods.adapter";
import { LoadingPulseIcon } from "@Models/icons";

const PaymentMethods = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrorFetching, setHasErrorFetching] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [currentModalData, setCurrentModalData] = useState({
    comisionMontoFijo: 0.0,
    comisionPorcentaje: 0.0,
  });
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  // 1. Call to  the api and get the paymnt methods
  const callGetPaymentMethodAdapter = async () => {
    const paymentMethods = await getPaymentMethods_adapter();
    if (!paymentMethods.isSuccessfully) setHasErrorFetching(true);
    else setPaymentMethods(paymentMethods.data);
    setHasErrorFetching(false);
    setIsLoading(false);
  };

  useEffect(() => {
    callGetPaymentMethodAdapter();
  }, []);

  if (isLoading) {
    return (
      <div className={styled.loadingContainer}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </div>
    );
  }

  if (hasErrorFetching)
    return (
      <article>
        <h1 className={styled.paymentMethod__errorMessage}>
          Lo sentimos, algo salió mal al cargar los métodos de pago.
          <br />
          Por favor, inténtelo de nuevo más tarde. 🔍
        </h1>
      </article>
    );

  return (
    <section className={styled.paymentMethod__container}>
      <div className={styled.paymentMethod__container_section}>
        {paymentMethods.map((method) => {
          const indexUUID = uuidv4();
          return (
            <CategoryMethod
              key={indexUUID}
              methodProps={method}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              canBeSelected={true}
            />
          );
        })}
      </div>
      {selectedPaymentMethod && (
        <div className={styled.paymentMethod__container_subsection}>
          {selectedPaymentMethod.subCategory.map((method) => {
            const indexUUID = uuidv4();
            return (
              <CategoryMethod
                key={indexUUID}
                methodProps={method}
                canBeSelected={false}
                onClick={() => setPaymentModalVisible(true)}
              />
            );
          })}
        </div>
      )}
      {/* //! NO BORRAR */}
      {/* <PaymentMethodModal
        data={currentModalData}
        controller={{
          visible: paymentModalVisible,
          setVisible: setPaymentModalVisible,
        }}
        paymentId={selectedPaymentMethod}
        confirmAction={
          currentModalData
            ? (props) => updatePaymentComision(props)
            : () => console.log("no hay data")
        }
      /> */}
    </section>
  );
};

export default PaymentMethods;
