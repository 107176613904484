import React from "react";

// Components

// Styles
import style from "./columnNavigationGuide.module.css";

const columnNavigationGuide = ({ currentSection, changeSection }) => {
  return (
    <div className={style.leftNavigationMenu}>
      <button
        className={`${style.navigationButton} ${
          currentSection === 0 ? style.activeSection : ""
        }`}
        onClick={() => changeSection(0)}
      >
        <p>Cajas</p>
      </button>
      <button
        className={`${style.navigationButton} ${
          currentSection === 1 ? style.activeSection : ""
        }`}
        onClick={() => changeSection(1)}
      >
        <p>Métodos de pago</p>
      </button>
      <button
        className={`${style.navigationButton} ${
          currentSection === 2 ? style.activeSection : ""
        }`}
        onClick={() => changeSection(2)}
      >
        <p>Movimientos</p>
      </button>
    </div>
  );
};

export default columnNavigationGuide;
