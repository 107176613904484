import { useAppDispatch, useAppSelector } from "./store";

import {
  modifyApiCashRegisterName,
  addApiCashRegister,
  modifyApiCashRegister,
  getApiCashRegisterDocuments,
  modifyApiCashRegisterDocuments,
  getApiCashRegisterRuc,
  getApiRucs,
  getApiDepartamentos,
  getApiProvincias,
  getApiDistritos,
  getApiBillingInfo,
  getApiDefaultBillingInfo,
  modifyApiBillingInfo,
  getApiPaymentMethods,
  deleteApiPaymentMethod,
  addApiPaymentMethod,
  modifyApiPaymentMethod,
  getApiMovementTypes,
  addApiMovementTypes,
  modifyApiMovementTypes,
  deleteApiMovementTypes,
} from "@Services/cashregister";

import {
  PaymentMethod,
  addPaymentMethod,
  deletePaymentMethod,
  modifyPaymentMethod,
  updatePaymentMethods,
} from "@ReduxService/states/paymentMethodsSlice";
import {
  addMovementType,
  deleteMovementType,
  updateMovementTypes,
  modifyMovementType,
} from "@ReduxService/states/movementTypesSlice";
import {
  CashRegister,
  addCashRegister,
  updateCashRegister,
} from "@ReduxService/states/cashRegisterSlice";
import { useSelector } from "react-redux";
import { defaultBillingDocuments } from "./consts/defaultBillingDocuments";

export const useCashRegistersActions = () => {
  const dispatch = useAppDispatch();

  /*
   *
   *  DATOS DE CAJA
   *
   */

  const useModifyCashRegisterName = async (data: {
    idCaja: number;
    nombreCaja: string;
  }) => {
    try {
      const response = await modifyApiCashRegisterName(data);
      if (response.status === 200) {
        const newData: CashRegister = {
          idSaleBox: data.idCaja,
          saleBoxName: data.nombreCaja,
        };
        dispatch(updateCashRegister(newData));
      }
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useCreateCashRegister = async (data: any) => {
    const thereIsAlmostOneVoucherType = data.voucherTypes.some(
      (vt) => vt.activeBit === 1
    );
    if (!thereIsAlmostOneVoucherType) {
      return {
        status: 500,
        message: "Debe seleccionar al menos un tipo de comprobante",
        data: {},
      };
    }
    try {
      const response = await addApiCashRegister(data);
      if (response.status === 200) {
        const newData: CashRegister = {
          idSaleBox: response.data.idSaleBox,
          saleBoxName: response.data.saleBoxName,
          open: response.data.estado,
          active: response.data.visible,
        };
        dispatch(addCashRegister(newData));
      }
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useModifyCashRegister = async (
    data: any,
    prevData: CashRegister,
    idCaja: number
  ) => {
    try {
      const response = await modifyApiCashRegister(data, idCaja);
      if (response.status === 200) {
        const newData: CashRegister = {
          ...prevData,
          saleBoxName: data.nameSaleBox,
        };
        dispatch(updateCashRegister(newData));
      }
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useSetCashRegisterDocuments = async (idCashRegister: number) => {
    try {
      const response = await getApiCashRegisterDocuments(idCashRegister);

      // Primero encontramos los documentos principales (boleta y factura)
      const boletaVenta = response.data.find((d) => d.tipoDocumento === 1);
      const factura = response.data.find((d) => d.tipoDocumento === 2);

      const formattedResponse = {
        ...response,
        data: defaultBillingDocuments.map((document) => {
          let foundDocument = response.data.find(
            (d) =>
              d.tipoDocumento === document.tipoDocumento &&
              d.creditNoteDocumentId === document.creditNoteDocumentId
          );

          // Si es una nota de crédito, su activo depende del documento principal
          if (document.tipoDocumento === 3) {
            // Nota de crédito
            if (document.creditNoteDocumentId === 1) {
              // Para boleta
              return {
                ...document,
                ...foundDocument,
                activo: boletaVenta?.activo || 0,
              };
            } else if (document.creditNoteDocumentId === 2) {
              // Para factura
              return {
                ...document,
                ...foundDocument,
                activo: factura?.activo || 0,
              };
            }
          }

          // Para otros documentos, mantener su estado normal
          return {
            ...document,
            ...foundDocument,
          };
        }),
      };
      return formattedResponse;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return {
        status: 500,
        data: {
          message: "Error al obtener los documentos de facturación",
        },
      };
    }
  };

  const useModifyCashRegisterDocuments = async (
    idCashRegister: number,
    billinOptions: any
  ) => {
    try {
      // Crea un arreglo de promesas de actualización y ejecútalas
      const updatePromises = billinOptions.map(async (billingOption) => {
        const modifiedBillingOption = {
          idCaja: idCashRegister,
          tipoDocumento: billingOption.tipoDocumento,
          serie: billingOption.serie,
          numero: billingOption.numero,
          activo: billingOption.activo,
        };
        return await modifyApiCashRegisterDocuments(modifiedBillingOption);
      });

      // Espera a que todas las promesas de actualización se completen
      const updates = await Promise.all(updatePromises);
      let successUpdate = true;
      updates.map((update: any) => {
        if (update?.status !== 200) {
          successUpdate = false;
        }
      });

      /* if (successUpdate) {
					dispatch(updateSiteSchedules(response.data));
				} */
      return successUpdate;
    } catch (error) {
      console.error("Error fetching schedules:", error);
      return []; // Indicar que ocurrió un error
    }
  };

  const useSetCashRegisterRuc = async (idCaja) => {
    try {
      const response = await getApiCashRegisterRuc(idCaja);
      return {
        ...response,
        data: response.data.map((ruc) => ({
          ...ruc,
          idDepartamento: ruc.idDepartament,
          idProvincia: ruc.idProvince,
          razonSocial: ruc.businessName,
          idDistrito: ruc.idDistrict,
          idCertificateBilling: 179,
          direccion: ruc.address,
          facturacionElectronica: ruc.billingElectronic,
        })),
      };
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useSetDepartamentos = async () => {
    try {
      const response = await getApiDepartamentos();
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null;
    }
  };

  const useSetProvincias = async (idDepartamento: number) => {
    try {
      const response = await getApiProvincias(idDepartamento);
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null;
    }
  };

  const useSetDistritos = async (idProvincia: number) => {
    try {
      const response = await getApiDistritos(idProvincia);

      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null;
    }
  };

  const useSetDefaultCashRegisterRuc = async () => {
    try {
      const response = await getApiRucs();
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useSetBillingInfo = async (idCashRegister: number) => {
    try {
      const response = await getApiBillingInfo(idCashRegister);
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return {
        status: 500,
        data: {
          message: "Error al obtener la información de facturación",
        },
      };
    }
  };

  const useSetDefaultBillingInfo = async () => {
    try {
      const response = await getApiDefaultBillingInfo();
      return {
        ...response,
        data: response.data.map((ruc) => ({
          ...ruc,
          tipoDocumento: ruc.documentType,
          serie: ruc.serialNumber,
          numero: ruc.number,
          activo: ruc.active,
          nombreDocumento: ruc.documentName,
          idLocal: ruc.idLounge,
        })),
      };
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useModifyBillingInfo = async (data: any) => {
    try {
      const response = await modifyApiBillingInfo(data);
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null;
    }
  };

  /*
   *
   *  MÉTODOS DE PAGO
   *
   */

  const useSetPaymentMethods = async () => {
    try {
      const response = await getApiPaymentMethods();

      if (response.status === 200) {
        dispatch(updatePaymentMethods(response.data));
      } else {
        dispatch(updatePaymentMethods([]));
      }

      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useDeletePaymentMethod = async (id: number) => {
    try {
      const response = await deleteApiPaymentMethod(id);

      if (response.status === 200) {
        dispatch(deletePaymentMethod(id));
      }

      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useAddPaymentMethod = async (data: PaymentMethod) => {
    try {
      const response = await addApiPaymentMethod(data);

      if (response.status === 200) {
        //dispatch(addPaymentMethod(data));
      }
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useModifyPaymentMethod = async (data: PaymentMethod) => {
    try {
      const response = await modifyApiPaymentMethod(data);

      if (response.status === 200) {
        dispatch(modifyPaymentMethod(data));
      }

      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  /*
   *
   *  CONCEPTOS DE MOVIMIENTO
   *
   */

  const useSetMovementTypes = async () => {
    try {
      const response = await getApiMovementTypes();

      // ordenar response.data por idcajamovimientoconcepto de forma descendente
      const orderDataAsc = response.data.sort((a: any, b: any) => {
        if (a.idcajamovimientoconcepto < b.idcajamovimientoconcepto) {
          return 1;
        }
        if (a.idcajamovimientoconcepto > b.idcajamovimientoconcepto) {
          return -1;
        }
        return 0;
      });

      if (response.status === 200) {
        dispatch(updateMovementTypes(orderDataAsc));
      } else {
        dispatch(updateMovementTypes([]));
      }

      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useAddMovementTypes = async (data: any) => {
    const response = await addApiMovementTypes(data);

    if (response.status === 200) {
      dispatch(addMovementType(response.data));
    }
    return response;
  };

  const useModifyMovementTypes = async (idMovement: number, data: any) => {
    const response = await modifyApiMovementTypes(idMovement, data);
    if (response.status === 200) {
      dispatch(
        modifyMovementType({
          idcajamovimientoconcepto: idMovement,
          tipomovimiento: data.idTipoMovimiento,
          descripcion: data.descripcion,
          bitactivo: 1,
        })
      );
    }
    return response;
  };

  const useDeleteMovementTypes = async (id: number) => {
    const response = await deleteApiMovementTypes(id);
    if (response.status === 200) {
      dispatch(deleteMovementType(id));
    }
    return response;
  };

  return {
    useModifyCashRegisterName,
    useCreateCashRegister,
    useModifyCashRegister,
    useSetCashRegisterDocuments,
    useModifyCashRegisterDocuments,
    useSetCashRegisterRuc,
    useSetDepartamentos,
    useSetProvincias,
    useSetDistritos,
    useSetDefaultCashRegisterRuc,
    useSetBillingInfo,
    useSetDefaultBillingInfo,
    useModifyBillingInfo,
    useSetPaymentMethods,
    useDeletePaymentMethod,
    useAddPaymentMethod,
    useModifyPaymentMethod,
    useSetMovementTypes,
    useAddMovementTypes,
    useModifyMovementTypes,
    useDeleteMovementTypes,
  };
};
