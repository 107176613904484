// eslint-disable
import { useState } from "react";
import style from "./SiteHomeButton.module.css";
import MoreOptions from "./components/MoreOptions/MoreOptions";
import {
  IconWrapper,
  Icon,
  ButtonWithoutIcon,
  ModalAcceptCancel,
  // @ts-ignore
} from "@viuti/recursos";
import { HiLockClosed } from "react-icons/hi";

// Icons
import {
  SiteIcon,
  CashRegisterStrokeIcon,
  TripleDotsIcon,
  TrashBinIcon,
} from "@Models/icons";

const onClickDefault = () => {
  // eslint-disable-next-line no-console
  //console.log("Clicked bttn");
};

interface ISiteHomeButtonProps {
  label: string;
  onClick?: () => void;
  isOpen?: boolean;
  type?: string;
  cashId?: number;
  hasMoreOptions?: boolean;
  isActive?: boolean;
  index?: number;
  isDowngraded?: boolean;
}

const SiteHomeButton = ({
  label,
  onClick = onClickDefault,
  isOpen = false,
  type = "site",
  cashId,
  hasMoreOptions = true,
  isActive = true,
  index,
  isDowngraded = false,
}: ISiteHomeButtonProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);

  const handleClick = () => {
    if (isDowngraded) {
      setShowDowngradeModal(true);
    } else {
      onClick();
    }
  };

  if (isDeactivating)
    return (
      <div className={style.siteMenuButtonContainer__updating}>
        <Icon path={TripleDotsIcon} color={"#9d8ae6"} size={28} />
        <p>Actualizando...</p>
      </div>
    );

  if (isDeleting)
    return (
      <div className={style.siteMenuButtonContainer__deleting}>
        <Icon path={TrashBinIcon} color={"#ba1a1a"} size={28} />
        <p>Eliminando...</p>
      </div>
    );

  return (
    <>
      <button
        className={`${
          isActive
            ? style.siteMenuButtonContainer
            : style.siteMenuButtonContainerInactive
        } ${isDowngraded ? style.siteMenuButtonContainerDowngraded : ""}`}
        onClick={() => handleClick()}
        id={"lounges-loungesList-item-" + index}
      >
        {hasMoreOptions && !isDowngraded && (
          <MoreOptions
            isCashActive={isActive}
            cashId={cashId}
            setIsDeleting={setIsDeleting}
            setIsDeactivating={setIsDeactivating}
          />
        )}
        <Icon
          path={type === "site" ? SiteIcon : CashRegisterStrokeIcon}
          color={isDowngraded ? "#B4B4B4" : "#9d8ae6"}
          size={48}
        />
        {type !== "site" && (
          <div
            className={`${style.siteMenuButtonStatus} ${
              isOpen
                ? style.siteMenuButtonStatusOpen
                : style.siteMenuButtonStatusClosed
            } ${!isActive && style.siteMenuButtonStatusInactive}`}
            data-testid="site-menu-button-open"
            title={`Caja ${
              !isActive ? "desactivada" : isOpen ? "abierta" : "cerrada"
            }`}
          />
        )}
        <p className={style.siteMenuButtonLabel} title={label}>
          {label?.length > 20 ? `${label.substring(0, 20)}...` : label}
        </p>
      </button>

      {showDowngradeModal && (
        <ModalAcceptCancel
          title="Caja deshabilitada"
          showButtons={{
            showButtonClose: true,
            showButtonOne: false,
            showButtonTwo: false,
          }}
          actionButtonClose={() => setShowDowngradeModal(false)}
        >
          <div className={style.downgradeModalContent}>
            <h2 className={style.modal__access__denied__title}>
              Lo sentimos, no tienes permisos para acceder a esta caja.
            </h2>
            <div className={style.modal__access__denied__icon__container}>
              <span className={style.modal__access__denied__icon}>
                <IconWrapper icon={HiLockClosed} size={48} color="#937cf4" />
              </span>
            </div>
            <p className={style.modal__access__denied__description}>
              Esta caja está deshabilitada automáticamente debido a la cantidad
              de cajas disponibles según tu plan actual. Para habilitarla
              nuevamente, actualiza tu plan a uno superior o ajusta la cantidad
              de cajas disponibles.
            </p>
            <ButtonWithoutIcon
              isPrimary
              textBttn="Actualizar plan"
              handleClick={() => window.location.replace("/mi-cuenta/planes")}
            />
          </div>
        </ModalAcceptCancel>
      )}
    </>
  );
};

export default SiteHomeButton;
