import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface Site {
  idLounge: number;
  idCompany: number;
  nameLounge: string;
  address: string;
  webSite: string;
  dataCountry: {
    idCountry: number;
    nameCountry: string;
  };
  departament: string;
}

const DEFAULT_STATE = {};

const initialState: Site = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const currentSiteSlice = createSlice({
  name: "currentSite",
  initialState,
  reducers: {
    updateCurrentSite: (state, action: PayloadAction<Site>) => {
      return action.payload;
    },
  },
});

export default currentSiteSlice.reducer;
export const { updateCurrentSite } = currentSiteSlice.actions;
