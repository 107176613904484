import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

import { apiDomain, getConfig } from "./api";

export const modifyApiCashRegisterName = async (data) => {
  try {
    const response = await axios.post(
      `${apiDomain}/lounges/v1/saleBox/ModificarNombreCaja`,
      data,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const addApiCashRegister = async (data) => {
  const añade_caja = 38;
  trackUserAction(añade_caja);
  try {
    const response = await axios.post(
      `${apiDomain}/caja/v1/SaleBox/SaleBox`,
      data,
      getConfig()
    );
    return response.data;
  } catch (error) {
    return error.response.data;
    //(error);
  }
};

export const modifyApiCashRegister = async (data, idCaja) => {
  try {
    const response = await axios.put(
      `${apiDomain}/caja/v1/SaleBox/SaleBox?idSaleBox=${idCaja}`,
      data,
      getConfig()
    );
    return response;
  } catch (error) {
    return error.response.data;
    //(error);
  }
};

export const getApiCashRegisterDocuments = async (idCashRegister) => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Billing/DocumentSaleBox?idSaleBox=${idCashRegister}`,
      getConfig()
    );
    return {
      ...response.data,
      data: response.data.data.map((document) => ({
        tipoDocumento: document.documentType,
        serie: document.serialNumber,
        numero: document.number,
        activo: document.active,
        nombreDocumento: `${document.documentName
          ?.charAt(0)
          .toUpperCase()}${document.documentName?.slice(1).toLowerCase()}`,
        idLocal: document.idLounge,
        creditNoteDocumentId: document.creditNoteDocumentId,
      })),
    };
  } catch (error) {
    //(error);
  }
};

export const modifyApiCashRegisterDocuments = async (data) => {
  try {
    const response = await axios.post(
      `${apiDomain}/locales/v1/Billings/ModificarDatosDeFacturacion`,
      data,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const getApiCashRegisterRuc = async (idCaja) => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Billing/ShowAddressBilling?IdSaleBox=${idCaja}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const getApiRucs = async () => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/billing/CertificatesBilling`,
      getConfig()
    );
    return {
      ...response.data,
      data: response.data.data.map((ruc) => ({
        idCertificado: ruc.idBillingCertificate,
        ruc: ruc.ruc,
        razonSocial: ruc.businessName,
        estado: ruc.estate,
      })),
    };
    // return response.data.map((ruc) => ({
    //   id: ruc.id,
    //   ruc: ruc.ruc,
    //   razonSocial: ruc.razonSocial,
    // }));
  } catch (error) {
    //(error);
  }
};

export const getApiDepartamentos = async () => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Billing/Departaments`,
      getConfig()
    );
    return {
      ...response.data,
      data: response.data.data.map((departamento) => ({
        idDepartamento: departamento.idDepartament,
        departamento: departamento.nameDepartament,
      })),
    };
  } catch (error) {
    //console.log(error);
  }
};

export const getApiProvincias = async (idDepartamento) => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Billing/Provinces?IdDepartament=${idDepartamento}`,
      getConfig()
    );

    return {
      ...response.data,
      data: response.data.data.map((provincia) => ({
        idProvincia: provincia.idProvince,
        provincia: provincia.provinceName,
      })),
    };
  } catch (error) {
    //(error);
  }
};

export const getApiDistritos = async (idProvincia) => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Billing/Districts?IdProvince=${idProvincia}`,
      getConfig()
    );

    return {
      ...response.data,
      data: response.data.data.map((distrito) => ({
        idDistrito: distrito.idDistrict,
        distrito: distrito.nameDistrict,
      })),
    };
  } catch (error) {
    //(error);
  }
};

export const getApiBillingInfo = async (idCashRegister) => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Billing/BillingComment?IdSaleBox=${idCashRegister}`,
      getConfig()
    );
    return {
      ...response.data,
      data: response.data.data.map((billingInfo) => ({
        comentarioFacturacion: billingInfo.billingComment,
      })),
    };
  } catch (error) {
    //(error);
  }
};

export const getApiDefaultBillingInfo = async () => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/AccountingDocumentType/AccountingDocumentTypes`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const modifyApiBillingInfo = async (data) => {
  try {
    const response = await axios.post(
      `${apiDomain}/locales/v1/Facturacion/ModificarInformacion`,
      data,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

/*
 *
 *  MÉTODOS DE PAGO
 *
 */

export const getApiPaymentMethods = async () => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/PaymentsMethods/PaymentMethods`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const deleteApiPaymentMethod = async (idPaymentMethod) => {
  const data = {
    idPago: idPaymentMethod,
  };
  const config = getConfig();

  try {
    const response = await axios.delete(
      `${apiDomain}/locales/v1/MetodosdePago/BorrarPago`,
      { data, ...config }
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const addApiPaymentMethod = async (paymentMethod) => {
  try {
    const response = await axios.post(
      `${apiDomain}/locales/v1/MetodosdePago/AgregarPago`,
      paymentMethod,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const modifyApiPaymentMethod = async (paymentMethod) => {
  try {
    const response = await axios.post(
      `${apiDomain}/locales/v1/MetodosdePago/ModificarPago`,
      paymentMethod,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

/*
 *
 *  CONCEPTOS DE MOVIMIENTO
 *
 */

export const getApiMovementTypes = async () => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/Concept/Concepts`,
      getConfig()
    );
    return {
      ...response.data,
      data: response.data.data.map((data) => ({
        idcajamovimientoconcepto: data.idSaleBoxMovementConcept,
        tipomovimiento: data.movementType,
        descripcion: data.description,
        bitactivo: data.activeBit,
      })),
    };
  } catch (error) {
    //(error);
  }
};

export const addApiMovementTypes = async (data) => {
  const agrega_concepto_de_movimiento = 39;
  trackUserAction(agrega_concepto_de_movimiento);
  try {
    const response = await axios.post(
      `${apiDomain}/lounges/v1/Concept/Concept`,
      {
        movementType: data.tipomovimiento,
        description: data.descripcion,
      },
      getConfig()
    );

    return {
      ...response.data,
      data: {
        idcajamovimientoconcepto: response.data.data.idSaleBoxMovementConcept,
        tipomovimiento: response.data.data.movementType,
        descripcion: response.data.data.description,
        bitactivo: response.data.data.activeBit,
      },
    };
  } catch (error) {
    return {
      status: 500,
      message: error?.response?.data?.message || "Error al agregar el concepto",
      data: null,
    };
  }
};

export const modifyApiMovementTypes = async (idMovement, data) => {
  try {
    const response = await axios.put(
      `${apiDomain}/lounges/v1/Concept/Concept?idMovementConcept=${idMovement}`,
      {
        idMovementType: data.idTipoMovimiento,
        descrption: data.descripcion,
      },
      getConfig()
    );

    return response.data;
  } catch (error) {
    return {
      status: 500,
      message:
        error?.response?.data?.message || "Error al modificar el concepto",
      data: null,
    };
  }
};

export const deleteApiMovementTypes = async (idMovement) => {
  const config = getConfig();

  try {
    const response = await axios.delete(
      `${apiDomain}/lounges/v1/Concept/Concept?idConcept=${idMovement}`,
      { ...config }
    );
    return response.data;
  } catch (error) {
    return {
      status: 500,
      message:
        error?.response?.data?.message || "Error al eliminar el concepto",
      data: null,
    };
  }
};
