import { getApiSites } from "@Services/sites";

export const getApiSitesAdapter = async () => {
  const response = await getApiSites();

  if (!response.isSuccess) {
    return response;
  }

  const formattedDataResponse = response.data.map((site) => {
    return {
      local: site.lounge,
      nombre: site.nameLounge,
    };
  });

  return {
    ...response,
    data: formattedDataResponse,
  };
};
