import axios from "axios";
import { apiDomain, getConfig } from "../api";

export const getPaymentMethods_service = async () => {
  const GET_METHODS_METHODS = `${apiDomain}/caja/v1/PaymentsMethods/PaymentMethods`;

  try {
    const response = await axios.get(GET_METHODS_METHODS, getConfig());

    return {
      isSuccessfully: true,
      message: "Payment methods fetched successfully",
      data: response.data,
    };
  } catch (err) {
    return {
      isSuccessfully: false,
      message: "Ups! Parece que tenemos un problema aqui, intenta mas tarde",
    };
  }
};
