import { configureStore } from "@reduxjs/toolkit";

// Data reducers
import sitesReducer from "./states/sitesSlice";
import currentSiteReducer from "./states/currentSiteSlice";
import siteSchedulesReducer from "./states/siteSchedulesSlice";
import cashRegisterReducer from "./states/cashRegisterSlice";
import paymentMethodsReducer from "./states/paymentMethodsSlice";
import movementTypesSlice from "./states/movementTypesSlice";
import planConfigReducer from "./states/planConfigSlice";

// Components reducers
import notificationReducer from "./states/notificationSlice";

export const store = configureStore({
  reducer: {
    listSites: sitesReducer,
    currentSite: currentSiteReducer,
    siteSchedules: siteSchedulesReducer,
    listCashRegisters: cashRegisterReducer,
    notificationConfig: notificationReducer,
    paymentMethods: paymentMethodsReducer,
    movementTypes: movementTypesSlice,
    planConfig: planConfigReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
