interface props {
  titleName: string;
  business: IBillingBusiness;
  local: IBillingLocal;
  billingCode: string;
  billingDocument: string;
  products: IBillingProducts[];
  client: IBillingClient;
  pricing?: IBillingOp;
}

export const billingPrintbuilder = ({
  titleName,
  business,
  local,
  billingCode,
  billingDocument,
  products,
  client,
  pricing,
}: props) => ({
  TITLE_NAME: titleName,
  BUSINESS: {
    NAME: business.NAME,
    RUC: business.RUC,
  },
  LOCAL: {
    NAME: local.NAME,
    LOCATION: local.LOCATION,
    EMAIL: local.EMAIL,
    PHONE: local.PHONE,
  },
  BILLING: {
    CODE: billingCode,
    DOCUMENT: billingDocument,
    PRODUCTS: products,
  },
  CLIENT: {
    PASSPORT: client.PASSPORT,
    NAME: client.NAME,
    LOCATION: client.LOCATION,
  },
  PRICING: {
    OP_ICBPER: pricing?.OP_ICBPER || 0,
    OP_EXONERADAS: pricing?.OP_EXONERADAS || 0,
  },
});
