import React, { useContext, useEffect, useState } from "react";

// Style
import style from "./movementsTypes.module.css";

// Components
import {
  Icon,
  ButtonWithoutIcon,
  SlideNotification,
  validateModuleAccess,
  //@ts-ignore
} from "@viuti/recursos";
import ConfirmModal from "@Components/molecules/confirmModal/ConfirmModal";
import MovementConceptModal from "@Components/molecules/movementsConceptsModal/MovementsConceptsModal";

// Hooks
import { useCashRegistersActions } from "@Hooks/useCashRegistersActions";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { HeaderContext } from "@Hooks/useSitesHeaderContext";
import {
  clearNotification,
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";

// Icons
import { LoadingPulseIcon, EditIcon, TrashBinIcon } from "@Models/icons";
import { MODIFY_LOUNGES_UUID } from "@Models/const/securityAccess";

const MovementsTypes = () => {
  const hasEditPermissions = validateModuleAccess(MODIFY_LOUNGES_UUID, false);
  const dispatch = useAppDispatch();
  const headerContext: any = useContext(HeaderContext);
  const {
    useSetMovementTypes,
    useAddMovementTypes,
    useModifyMovementTypes,
    useDeleteMovementTypes,
  } = useCashRegistersActions();

  const movementTypesState = useAppSelector((state) => state.movementTypes);
  const inputMovementTypes = movementTypesState.filter(
    (movement) => movement.tipomovimiento === 1
  );
  const outputMovementTypes = movementTypesState.filter(
    (movement) => movement.tipomovimiento === 2
  );
  const notificationState = useAppSelector((state) => state.notificationConfig);

  const [currentModalData, setCurrentModalData] = useState(null);
  const [conceptsModalVisible, setConceptsModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [promiseStatus, setPromiseStatus] = useState({
    movementsTypes: { status: 0, data: [] },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        headerContext.handleIsHidden(true);
        //eslint-disable-next-line
        const movementTypesData = await useSetMovementTypes();

        setPromiseStatus({
          movementsTypes: {
            status: movementTypesData.status,
            data: movementTypesData.data,
          },
        });
      } catch (error) {
        setPromiseStatus({
          movementsTypes: { status: 401, data: null },
        });
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  const handleAddMovementType = async (data) => {
    const movement = {
      descripcion: data.descripcion,
      tipomovimiento: data.tipomovimiento,
    };

    //eslint-disable-next-line
    const response = await useAddMovementTypes(movement);

    if (response.status === 200) {
      dispatch(updateNotificationStatus(200));
      dispatch(updateNotificationMessage("Concepto agregado con éxito"));
    } else {
      dispatch(updateNotificationStatus(400));
      dispatch(updateNotificationMessage(response.message));
    }
    return response.status;
  };

  const handleModifyMovementType = async (data) => {
    const movement = {
      idTipoMovimiento: data.tipomovimiento,
      descripcion: data.descripcion,
    };

    //eslint-disable-next-line
    const response = await useModifyMovementTypes(
      data.idcajamovimientoconcepto,
      movement
    );

    if (response.status === 200) {
      dispatch(updateNotificationStatus(200));
      dispatch(updateNotificationMessage("Concepto modificado con éxito"));
      setConceptsModalVisible(false);
    } else {
      dispatch(updateNotificationStatus(400));
      dispatch(updateNotificationMessage(response.message));
    }
    return response.status;
  };

  const handleDeleteMovementType = async (data) => {
    //eslint-disable-next-line
    const response = await useDeleteMovementTypes(
      data.idcajamovimientoconcepto
    );

    if (response.status === 200) {
      dispatch(updateNotificationStatus(200));
      dispatch(updateNotificationMessage("Concepto eliminado con éxito"));
      setDeleteModalVisible(false);
    } else {
      dispatch(updateNotificationStatus(400));
      dispatch(updateNotificationMessage(response.message));
    }
    return response.status;
  };

  if (promiseStatus.movementsTypes.status === 0) {
    return (
      <div className={style.loadingContainer}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </div>
    );
  }

  if (
    promiseStatus.movementsTypes.status !== 0 &&
    promiseStatus.movementsTypes.status !== 200
  ) {
    return (
      <article>
        <h1 className={style.conceptMethods__errorMessage}>
          Lo sentimos, algo salió mal al cargar los conceptos de movimiento.
          <br />
          Por favor, inténtelo de nuevo más tarde. 🔍
        </h1>
      </article>
    );
  }

  const ConceptCard = ({ data }) => {
    return (
      <div
        className={`${style.columnItem} ${
          data.tipomovimiento === 1 ? style.inputCard : style.outputCard
        }`}
      >
        <p>{data.descripcion}</p>
        <span className={style.conceptsActionsContainer}>
          <button
            className={`${style.conceptActionsButton} ${style.editAction}`}
            onClick={() => {
              validateModuleAccess(MODIFY_LOUNGES_UUID) &&
                setConceptsModalVisible(true);
              setCurrentModalData(data);
            }}
          >
            <Icon path={EditIcon} color={"#7663C3"} size={16} />
          </button>
          <button
            className={`${style.conceptActionsButton} ${style.removeAction}`}
            onClick={() => {
              validateModuleAccess(MODIFY_LOUNGES_UUID) &&
                setDeleteModalVisible(true);
              setCurrentModalData(data);
            }}
          >
            <Icon path={TrashBinIcon} color={"#C36363"} size={16} />
          </button>
        </span>
      </div>
    );
  };

  return (
    <div className={style.movementsContainer}>
      <div className={style.newConceptContainer}>
        <ButtonWithoutIcon
          isPrimary
          textBttn={"Agregar concepto de movimiento"}
          handleClick={() => {
            validateModuleAccess(MODIFY_LOUNGES_UUID) &&
              setConceptsModalVisible(true);
            setCurrentModalData(null);
          }}
        />
      </div>
      <div className={style.menuContainer}>
        <div className={style.menuItem}>
          <div className={`${style.columnTitle} ${style.outputCard}`}>
            <p>Salidas</p>
          </div>
          {outputMovementTypes.map((movement: any, index) => (
            <ConceptCard data={movement} key={index} />
          ))}
        </div>
        <div className={style.menuItem}>
          <div className={`${style.columnTitle} ${style.inputCard}`}>
            <p>Entradas</p>
          </div>
          {inputMovementTypes.map((movement: any, index) => (
            <ConceptCard data={movement} key={index} />
          ))}
        </div>
      </div>

      <ConfirmModal
        title={"Eliminar concepto"}
        message={
          "Estás a punto de eliminar un concepto de movimiento, ¿estás seguro?"
        }
        buttonLbl={"Eliminar"}
        controller={{
          visible: deleteModalVisible,
          setVisible: setDeleteModalVisible,
        }}
        confirmAction={handleDeleteMovementType}
        data={currentModalData}
      />
      <MovementConceptModal
        data={currentModalData}
        controller={{
          visible: conceptsModalVisible,
          setVisible: setConceptsModalVisible,
        }}
        confirmAction={
          currentModalData ? handleModifyMovementType : handleAddMovementType
        }
      />
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </div>
  );
};

export default MovementsTypes;
