import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

import { apiDomain, getConfig } from "./api";

export const getApiSites = async () => {
  const ingresa_vista_locales = 34;
  trackUserAction(ingresa_vista_locales);
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/lounges/lounges`,
      getConfig()
    );
    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message: error?.response?.data?.message || "Error al obtener las sedes",
      data: null,
    };
  }
};

export const getApiSiteData = async (id) => {
  const ve_informacion_del_local = 35;
  trackUserAction(ve_informacion_del_local);
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/lounges/DataLounge?IdLounge=${id}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const updateApiSite = async (data) => {
  const edita_informacion_del_local = 36;
  trackUserAction(edita_informacion_del_local);
  try {
    const response = await axios.put(
      `${apiDomain}/lounges/v1/lounges/dataLounge`,
      data,
      getConfig()
    );
    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message: error?.response?.data?.message || "Error al actualizar la sede",
      data: null,
    };
  }
};

export const getApiSchedules = async (idSite) => {
  try {
    const response = await axios.get(
      `${apiDomain}/lounges/v1/shedules/shedules?IdLounge=${idSite}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const addApiSchedules = async (data) => {
  try {
    const response = await axios.post(
      `${apiDomain}/lounges/v1/Shedules/Shedule`,
      data,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const updateApiSchedules = async (data) => {
  try {
    const response = await axios.put(
      `${apiDomain}/lounges/v1/Shedules/Shedules`,
      data,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const deleteApiSchedules = async (id) => {
  getConfig();
  try {
    const response = await axios.delete(
      `${apiDomain}/lounges/v1/shedules/shedule?idShedule=${id}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const getApiCashRegisters = async (idSite) => {
  try {
    const response = await axios.get(
      `${apiDomain}/caja/v1/saleBox/LocalSaleBox?IdLounge=${idSite}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};

export const getApiPlanConfig = async () => {
  try {
    const response = await axios.get(
      `${apiDomain}/cuenta/v1/Account/PlanLimits`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    //(error);
  }
};
