import { useContext, useEffect, useState } from "react";
import {
  TextInput,
  SelectInput,
  SlideNotification,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import style from "./siteInformation.module.css";
import {
  updateNotificationMessage,
  updateNotificationStatus,
  clearNotification,
} from "@ReduxService/states/notificationSlice";
import { updateCurrentSite } from "@ReduxService/states/currentSiteSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { updateApiSite } from "@Services/sites";
import { handleFieldBlur, handleFieldChange } from "@Utilities/FormHandler";
import {
  HeaderContext,
  HeaderContextProps,
} from "@Hooks/useSitesHeaderContext";
import { updateSiteName } from "@ReduxService/states/sitesSlice";
import { MODIFY_LOUNGES_UUID } from "@Models/const/securityAccess";

const SiteInformation = () => {
  const hasEditPermissions = validateModuleAccess(MODIFY_LOUNGES_UUID, false);
  const dispatch = useAppDispatch();
  const headerContext = useContext(HeaderContext) as HeaderContextProps;

  headerContext.handleIsHidden(false);

  const currentSiteState = useAppSelector((state) => state.currentSite);

  const initialValues = {
    ...currentSiteState,
    nameCountry: currentSiteState.dataCountry.nameCountry,
  };

  const [editedFormValues, setEditedFormValues] = useState({
    ...initialValues,
    nameCountry: initialValues.nameCountry || "",
  });

  const countriesList = [
    {
      idCountry: 1,
      nameCountry: "PERÚ",
    },
  ];

  const selectedCountry = countriesList.find(
    (country) => country.nameCountry === editedFormValues.nameCountry
  );
  let idCountry = selectedCountry ? selectedCountry.idCountry : 0;

  const countriesListNames = countriesList.map(
    (country) => country.nameCountry
  );

  const [errors] = useState({
    nameLounge: "",
    webSite: "",
    address: "",
    departament: "",
    nameCountry: "",
  });

  const [touched, setTouched] = useState({
    nameLounge: false,
    webSite: false,
    address: false,
    departament: true,
    nameCountry: false,
  });

  const validationSchema = (name, value) => {
    switch (name) {
      case "nameLounge":
        if (!value) {
          errors.nameLounge = "Campo requerido";
        } else {
          errors.nameLounge = "";
        }
        break;
      case "webSite":
        // regex for url includes domain and '.' custom end
        const regexIncludeDomain = new RegExp(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
        );

        if (!value) {
          errors.webSite = "";
        } else if (!regexIncludeDomain.test(value) || !value.includes(".")) {
          errors.webSite = "El sitio web no es válido";
        } else {
          errors.webSite = "";
        }
        break;
      case "address":
        if (!value) {
          errors.address = "Campo requerido";
        } else {
          errors.address = "";
        }
        break;
      case "departament":
        if (!value) {
          errors.departament = "Campo requerido";
        } else {
          errors.departament = "";
        }
        break;
      case "nameCountry":
        if (!value) {
          errors.nameCountry = "Campo requerido";
        } else {
          errors.nameCountry = "";
        }
        break;
      default:
        break;
    }
  };

  const localHandleFieldBlur = (event) => {
    handleFieldBlur(event, setTouched, validationSchema);
  };

  const localHandleFieldChange = (event) => {
    handleFieldChange(event, setEditedFormValues);
    validationSchema(event.target.name, event.target.value);
  };

  const submitAction = async () => {
    // por cada key en error ejecutar validationSchema con el valor de la key
    Object.keys(errors).forEach((key) => {
      validationSchema(key, editedFormValues[key]);
    });

    // Si hay algún error, no se envía la petición
    if (Object.values(errors).some((error) => error !== "")) {
      dispatch(
        updateNotificationMessage(
          "Ups! Revise que todos los campos estén completos."
        )
      );
      dispatch(updateNotificationStatus(400));
      return;
    }

    headerContext.handleIsLoading(true);
    headerContext.handleIsDisabled(true);

    try {
      const formatedValues = {
        idLounge: editedFormValues.idLounge,
        loungeName: editedFormValues.nameLounge,
        address: editedFormValues.address,
        webSite: editedFormValues.webSite,
        idCountry: idCountry,
        departament: editedFormValues.departament,
      };

      const response = await updateApiSite(formatedValues);

      if (response.isSuccess) {
        dispatch(updateNotificationMessage(response.data));
        dispatch(updateNotificationStatus(response.status));
        dispatch(
          updateCurrentSite({
            ...editedFormValues,
            dataCountry: {
              idCountry: idCountry,
              nameCountry: editedFormValues.nameCountry,
            },
          })
        );
        dispatch(
          updateSiteName({
            idLounge: formatedValues.idLounge,
            name: formatedValues.loungeName,
          })
        );
        headerContext.handleIsLoading(false);
      } else {
        dispatch(updateNotificationMessage(response.message));
        dispatch(updateNotificationStatus(response.status));
        headerContext.handleIsLoading(false);
        headerContext.handleIsDisabled(false);
      }
    } catch (error) {
      dispatch(
        updateNotificationMessage("Ups! Hubo un error al actualizar la sede.")
      );
      dispatch(updateNotificationStatus(400));
      headerContext.handleIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      errors.nameLounge === "" &&
      errors.webSite === "" &&
      errors.address === "" &&
      errors.nameCountry === "" &&
      JSON.stringify(editedFormValues) !== JSON.stringify(initialValues)
    ) {
      headerContext.handleIsDisabled(false);
      headerContext.handleClickChange(submitAction);
    } else {
      headerContext.handleIsDisabled(true);
      headerContext.handleClickChange(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, touched, editedFormValues]);

  // Slide Notification
  const notificationState = useAppSelector((state) => state.notificationConfig);

  return (
    <div className={style.siteInformationContainer}>
      <div className={style.formRow}>
        <TextInput
          label="Nombre de la sede"
          value={editedFormValues.nameLounge}
          touched={touched.nameLounge}
          error={errors.nameLounge}
          handleBlur={localHandleFieldBlur}
          handleChange={localHandleFieldChange}
          placeholder={"Nombre"}
          keyboardType={"text"}
          name={"nameLounge"}
          flex={1}
          required
          disabled={!hasEditPermissions}
        />
        <TextInput
          label="Sitio web"
          value={editedFormValues.webSite}
          touched={touched.webSite}
          error={errors.webSite}
          handleBlur={localHandleFieldBlur}
          handleChange={localHandleFieldChange}
          placeholder={"Sitio"}
          keyboardType={"text"}
          name={"webSite"}
          flex={1}
          disabled={!hasEditPermissions}
        />
      </div>
      <div className={style.formRow}>
        <SelectInput
          label="País"
          value={editedFormValues.nameCountry}
          touched={touched.nameCountry}
          error={errors.nameCountry}
          handleBlur={localHandleFieldBlur}
          handleChange={localHandleFieldChange}
          keyboardType={"text"}
          name={"nameCountry"}
          flex={1}
          items={countriesListNames}
          required
          disabled={!hasEditPermissions}
        />
        <TextInput
          label="Dirección"
          value={editedFormValues.address}
          touched={touched.address}
          error={errors.address}
          handleBlur={localHandleFieldBlur}
          handleChange={localHandleFieldChange}
          placeholder={"Dirección"}
          keyboardType={"text"}
          name={"address"}
          flex={1}
          required
          disabled={!hasEditPermissions}
        />
      </div>
      <div className={`${style.formRow} ${style.form__half_row}`}>
        <TextInput
          label="Stand/Local/Dpto"
          value={editedFormValues.departament}
          touched={touched.departament}
          error={errors.departament}
          handleBlur={localHandleFieldBlur}
          handleChange={localHandleFieldChange}
          placeholder={"Departamento  "}
          keyboardType={"text"}
          name={"departament"}
          flex={1}
          required
          disabled={!hasEditPermissions}
        />
      </div>
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </div>
  );
};

export default SiteInformation;
