import { inactiveCashRegister } from "@ReduxService/states/cashRegisterSlice";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { inactiveCashService } from "@Services/activeCashService/inactiveCashService";

export const onInactiveLocal = async (
  cashId: number,
  dispatch,
  cashRegisters,
  setIsDeactivating: Function
) => {
  setIsDeactivating(true);
  const INACTIVE_BIT = 0;

  const backendRequestBody = {
    idSaleBox: cashId,
    visible: INACTIVE_BIT,
  };

  const hasAlmostOneCashRegisterActive =
    cashRegisters.filter((el) => el.active === 1).length > 1;

  if (!hasAlmostOneCashRegisterActive) {
    dispatch(
      updateNotificationMessage(
        "Lo siento, es necesario que permanezca al menos una caja activada."
      )
    );
    dispatch(updateNotificationStatus(400));
    setIsDeactivating(false);
    return;
  } else {
    const backendResponse = await inactiveCashService(backendRequestBody);

    // ERROR PATH
    if (!backendResponse.isSuccess) {
      dispatch(updateNotificationMessage(backendResponse.errorMessage));
      dispatch(updateNotificationStatus(400));
      setIsDeactivating(false);
      return;
    }

    // SUCCESS PATH
    dispatch(inactiveCashRegister(cashId));
    dispatch(updateNotificationMessage(backendResponse.successMessage));
    dispatch(updateNotificationStatus(200));
    setIsDeactivating(false);
  }
};
