// Router
import { Route, Routes } from "react-router-dom";
import { SITES_ROUTE } from "@Models/routes";

// Views
import SitesPage from "@Pages/sitesHome/sitesHome";
import SiteDetailsPage from "@Pages/siteDetails/siteDetails";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";

// Style
import style from "./context.module.css";

const SitesRoute = () => {
  return (
    <div className={style.pageContainer}>
      <Routes>
        <Route path={SITES_ROUTE.HOME_ROUTE} element={<SitesPage />} />
        <Route path={SITES_ROUTE.SITE_ROUTE} element={<SiteDetailsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default SitesRoute;
