import React from "react";

// Styles
import style from "./SchedulesContainer.module.css";

// Components
import ScheduleRow from "@Components/atoms/ScheduleRow/ScheduleRow";

const SchedulesContainer = ({
  schedules,
  editedFormValues,
  setEditedFormValues,
  disabled = false,
}) => {
  return (
    <div className={style.schedulesContainer}>
      {schedules.map((schedule, index) => {
        const uniqueKey = `${schedule.idcodigodia}-${schedule.idhorario}`;
        return (
          <ScheduleRow
            key={uniqueKey}
            index={index}
            schedule={schedule}
            editedFormValues={editedFormValues}
            setEditedFormValues={setEditedFormValues}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default SchedulesContainer;
