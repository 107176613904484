import { getPaymentMethods_service } from "@Services/paymentMethod/getPaymentMethod.service";

export const getPaymentMethods_adapter = async () => {
  //1. Call the service and get the data
  const backendRes = await getPaymentMethods_service();

  //2. Return the data (success)
  if (backendRes.isSuccessfully) {
    // 2.1 Adapt the response to the frontend
    return {
      isSuccessfully: true,
      message: backendRes.message,
      data: backendRes.data.data.map((paymentMethod: any) => ({
        categoryName: paymentMethod.categoryPaymnetName,
        categoryId: paymentMethod.categoryPaymnetId,
        subCategory: paymentMethod.subCategoryPayments.map(
          (subCategoryPayment: any) => ({
            categoryId: subCategoryPayment.subCategoryPaymentId,
            categoryName: subCategoryPayment.subCategoryPaymentName,
            /* commision: {
						paymentCommsionId: subCategoryPayment.paymentsComissions.idPaymentMethod,
						fixAmountComission: subCategoryPayment.paymentsComissions.fixAmountComission,
						percentageComission: subCategoryPayment.paymentsComissions.percentageComission,
					}, */
          })
        ),
      })),
    };
  }

  // 3. Resolve the error (error)
  return backendRes;
};
