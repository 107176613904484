import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface Schedule {
  idHorario: number;
  codigodia: number;
  apertura: string;
  cierre: string;
  desactivado: number;
}

const DEFAULT_STATE = [];

const initialState: Schedule[] = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const siteSchedules = createSlice({
  name: "siteSchedules",
  initialState,
  reducers: {
    updateSiteSchedules: (state, action: PayloadAction<Schedule[]>) => {
      return action.payload;
    },
  },
});

export default siteSchedules.reducer;
export const { updateSiteSchedules } = siteSchedules.actions;
