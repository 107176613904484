import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface CashRegister {
  idSaleBox: number;
  saleBoxName: string;
  open?: number;
  active?: number;
  isDowngraded?: boolean;
}

const DEFAULT_STATE = [];

const initialState: CashRegister[] = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const cashRegisterSlice = createSlice({
  name: "listCashRegisters",
  initialState,
  reducers: {
    updateCashRegisters: (state, action: PayloadAction<CashRegister[]>) => {
      action.payload;
      return action.payload;
    },
    addCashRegister: (state, action: PayloadAction<CashRegister>) => {
      state.push(action.payload);
    },
    updateCashRegister: (state, action: PayloadAction<CashRegister>) => {
      const newState = state.map((item) => {
        if (item.idSaleBox === action.payload.idSaleBox) {
          return action.payload;
        }
        return item;
      });
      return newState;
    },
    deleteCashRegister: (state, action: PayloadAction<number>) => {
      const newState = state.filter(
        (item) => item.idSaleBox !== action.payload
      );
      return newState;
    },
    activeCashRegister: (state, action: PayloadAction<number>) => {
      const copyState = state;
      const newState = copyState.map((item) =>
        item.idSaleBox === action.payload ? { ...item, active: 1 } : item
      );
      return newState;
    },
    inactiveCashRegister: (state, action: PayloadAction<number>) => {
      const copyState = state;
      const newState = copyState.map((item) =>
        item.idSaleBox === action.payload ? { ...item, active: 0 } : item
      );
      return newState;
    },
  },
});

export default cashRegisterSlice.reducer;
export const {
  updateCashRegisters,
  updateCashRegister,
  addCashRegister,
  deleteCashRegister,
  activeCashRegister,
  inactiveCashRegister,
} = cashRegisterSlice.actions;
