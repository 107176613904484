// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w6AG4Oxb9YuAvBbtO2qW {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dTWH1BebqqwBUjKBU78o {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cf31vUP24VGoOcJc428T {
  color: #4d4d4d;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0px 15px 0px;
}

.LMFxycZLSC3kRiOvNE9n {
  color: #4d4d4d;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/siteDetails/siteDetails.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".loadingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.errorViewContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.primaryErrorMsg {\n  color: #4d4d4d;\n  font-family: Mulish;\n  font-size: 18px;\n  font-weight: 700;\n  margin: 10px 0px 15px 0px;\n}\n\n.secondaryErrorMsg {\n  color: #4d4d4d;\n  font-family: Mulish;\n  font-size: 14px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `w6AG4Oxb9YuAvBbtO2qW`,
	"errorViewContainer": `dTWH1BebqqwBUjKBU78o`,
	"primaryErrorMsg": `cf31vUP24VGoOcJc428T`,
	"secondaryErrorMsg": `LMFxycZLSC3kRiOvNE9n`
};
export default ___CSS_LOADER_EXPORT___;
