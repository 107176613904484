import { AltArrowLeftIcon, EditIcon } from "@Models/icons";
import styled from "./categoryMethod.module.css";

interface props {
  methodProps: any;
  setSelectedPaymentMethod?: any;
  selectedPaymentMethod?: any;
  canBeSelected: boolean;
  onClick?: any;
}
export const CategoryMethod = ({
  methodProps,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  canBeSelected,
  onClick,
}: props) => {
  var isSelectedThisMethod = false;
  const handleOnClick = () => {
    if (!setSelectedPaymentMethod) return;
    if (!methodProps?.subCategory) return;

    if (isSelectedThisMethod) return setSelectedPaymentMethod(null);

    setSelectedPaymentMethod({
      categorySelected: methodProps.categoryName,
      subCategory: methodProps.subCategory,
    });
  };

  if (canBeSelected) {
    if (selectedPaymentMethod?.categorySelected === methodProps.categoryName) {
      isSelectedThisMethod = true;
    }
  }

  return (
    <button
      className={
        isSelectedThisMethod
          ? styled.categoryMethod__container_active
          : styled.categoryMethod__container
      }
      onClick={!canBeSelected ? onClick : handleOnClick}
    >
      <p>
        {methodProps.categoryName.charAt(0).toUpperCase() +
          methodProps.categoryName.slice(1)}
      </p>
      {canBeSelected && (
        <img
          className={
            isSelectedThisMethod
              ? styled.categoryIcon__rotateLeft
              : styled.categoryIcon__rotateRight
          }
          src={AltArrowLeftIcon}
          alt="right-icon"
        />
      )}
      {/* <img className={styled.icon} src={Pencil} alt="right-icon" /> */}
    </button>
  );
};
