import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface Sede {
  idLounge: number;
  name: string;
}

const DEFAULT_STATE = [];

const initialState: Sede[] = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const sitesSlice = createSlice({
  name: "listSites",
  initialState,
  reducers: {
    editSiteById: (state, action: PayloadAction<Sede>) => {
      const id = action.payload.idLounge;
      return state.map((site) =>
        site.idLounge === id ? action.payload : site
      );
    },
    updateSites: (state, action: PayloadAction<Sede[]>) => {
      return action.payload;
    },
    updateSiteName: (state, action: PayloadAction<Sede>) => {
      const id = action.payload.idLounge;
      return state.map((site) =>
        site.idLounge === id ? action.payload : site
      );
    },
  },
});

export default sitesSlice.reducer;
export const { editSiteById, updateSites, updateSiteName } = sitesSlice.actions;
