export const apiDomain = "https://gw.zymple.io";

export const getToken = () => {
  return localStorage.getItem("access_token");
};

const actualizarAuthorization = () => {
  const Config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return Config;
};

export const getConfig = () => {
  return actualizarAuthorization();
};
