import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//@ts-ignore
import { SlideNotification, validateModuleAccess } from "@viuti/recursos";
import style from "./siteSchedule.module.css";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  updateNotificationMessage,
  updateNotificationStatus,
  clearNotification,
} from "@ReduxService/states/notificationSlice";
import { updateSiteSchedules } from "@ReduxService/states/siteSchedulesSlice";

// Hooks
import { useSitesAction } from "@Hooks/useSitesActions";
import { HeaderContext } from "@Hooks/useSitesHeaderContext";
import SchedulesContainer from "@Components/molecules/schedulesContainer/SchedulesContainer";
import { MODIFY_LOUNGES_UUID } from "@Models/const/securityAccess";

const SiteSchedule = () => {
  const { idSede } = useParams();
  const dispatch = useAppDispatch();
  const headerContext: any = useContext(HeaderContext);
  const hasEditPermissions = validateModuleAccess(MODIFY_LOUNGES_UUID, false);

  const initialValues = useAppSelector((state) => state.siteSchedules);
  const [editedFormValues, setEditedFormValues] = useState(initialValues);

  const { useUpdateSchedules, useAddSchedules, useDeleteSchedules } =
    useSitesAction();

  //? Auto-disable header button
  useEffect(() => {
    headerContext.handleIsHidden(false);
    headerContext.handleIsDisabled(true);
    headerContext.handleClickChange(() => {});
  }, [dispatch]);

  function findDifferences(initialValues, editedFormValues) {
    const differentObjects = [];
    for (let i = 0; i < editedFormValues.length; i++) {
      const editedObject = editedFormValues[i];
      const initialObject = initialValues.find(
        (obj) => obj.idHorario === editedObject.idHorario
      );

      if (!initialObject) {
        differentObjects.push(editedObject);
      } else if (
        editedObject.apertura !== initialObject.apertura ||
        editedObject.cierre !== initialObject.cierre ||
        editedObject.desactivado !== initialObject.desactivado
      ) {
        differentObjects.push(editedObject);
      }
    }

    return differentObjects;
  }

  const submitAction = async () => {
    headerContext.handleIsLoading(true);
    headerContext.handleIsDisabled(true);

    const differences = findDifferences(initialValues, editedFormValues);

    const updateSchedules = differences.filter((item) => item.idHorario);
    const newSchedules = differences.filter((item) => !item.idHorario);
    const removedSchedules = initialValues.filter((item) => {
      return !editedFormValues.find((obj) => obj.idHorario === item.idHorario);
    });

    const deleteResponse = await useDeleteSchedules(
      Number(idSede),
      removedSchedules
    );
    const response = await useUpdateSchedules(Number(idSede), updateSchedules);
    const newsResponse = await useAddSchedules(Number(idSede), newSchedules);

    if (
      newsResponse.status === 200 &&
      response.status === 200 &&
      deleteResponse.status === 200
    ) {
      dispatch(updateSiteSchedules(editedFormValues));
      dispatch(updateNotificationMessage("Horarios actualizados"));
      dispatch(updateNotificationStatus(200));
    } else {
      dispatch(
        updateNotificationMessage("Verifique que los horarios sean válidos")
      );
      dispatch(updateNotificationStatus(400));
      headerContext.handleIsDisabled(false);
    }
    headerContext.handleIsLoading(false);
  };

  useEffect(() => {
    if (JSON.stringify(editedFormValues) !== JSON.stringify(initialValues)) {
      headerContext.handleIsDisabled(false);
      headerContext.handleClickChange(submitAction);
    } else {
      headerContext.handleClickChange(() => {});
      headerContext.handleIsDisabled(true);
    }
  }, [editedFormValues]);

  // Slide Notification
  const notificationState = useAppSelector((state) => state.notificationConfig);

  // Group schedules by day
  const getDaysWithSchedules = (editedFormValues) => {
    const daysWithSchedules = {};
    editedFormValues.forEach((schedule) => {
      const day = schedule.idcodigodia;
      if (daysWithSchedules[day]) {
        daysWithSchedules[day].push(schedule);
      } else {
        daysWithSchedules[day] = [schedule];
      }
    });

    return daysWithSchedules;
  };

  const daysWithSchedules = getDaysWithSchedules(editedFormValues);

  // Reordenar las claves para que el día 1 (domingo) aparezca al final
  const orderedDays = Object.keys(daysWithSchedules).sort((a, b) => {
    if (a === "1") return 1;
    if (b === "1") return -1;
    return parseInt(a) - parseInt(b);
  });

  return (
    <div className={style.scheduleRowsContainer}>
      {orderedDays.map((day) => {
        return (
          <SchedulesContainer
            key={day}
            schedules={daysWithSchedules[day]}
            editedFormValues={editedFormValues}
            setEditedFormValues={setEditedFormValues}
            disabled={!hasEditPermissions}
          />
        );
      })}

      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </div>
  );
};

export default SiteSchedule;
