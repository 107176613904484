// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NJtXL9v6pexsr9cfUJ_t {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #ffffff81;
  padding: 30px;
  transition: all 150ms ease-in-out;
}

.NJtXL9v6pexsr9cfUJ_t > img {
  height: auto;
}

.B4xmE5SCGJqmZp5fZejN {
  opacity: 1;
  pointer-events: all;
}

.qc6RlM_UAAleP0ApVuqw {
  opacity: 0;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/LoadingScreen/loadingScreen.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,2BAA2B;EAC3B,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,oBAAoB;AACtB","sourcesContent":[".loadingContainer {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  background-color: #ffffff81;\n  padding: 30px;\n  transition: all 150ms ease-in-out;\n}\n\n.loadingContainer > img {\n  height: auto;\n}\n\n.visible {\n  opacity: 1;\n  pointer-events: all;\n}\n\n.hidden {\n  opacity: 0;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `NJtXL9v6pexsr9cfUJ_t`,
	"visible": `B4xmE5SCGJqmZp5fZejN`,
	"hidden": `qc6RlM_UAAleP0ApVuqw`
};
export default ___CSS_LOADER_EXPORT___;
