import { useContext, useEffect, useRef, useState } from "react";
import style from "./cashRegisterDetails.module.css";
import {
  TextInput,
  SwitchInput,
  SelectInput,
  ButtonWithoutIcon,
  TextAreaInput,
  FormAuthorizeRuc,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import { handleFieldChange } from "@Utilities/FormHandler";
import { LoadingPulseIcon, UserInfoIcon } from "@Models/icons";
import { useCashRegistersActions } from "@Hooks/useCashRegistersActions";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { HeaderContext } from "@Hooks/useSitesHeaderContext";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleBillingSerieChange } from "./functions/handleBillingSerieChange.function";
import GeneratePrintableHTML from "./functions/handlePrint";
import { useReactToPrint } from "react-to-print";
import { billingPrintbuilder } from "./const/billingPrintTestData";
import { getDefaultBillingInfoAdapter } from "../../adapters/getCashRegister/getDefaultBillingInfo.adapter";
import { MODIFY_LOUNGES_UUID } from "@Models/const/securityAccess";
import ConfirmModal from "@Components/molecules/confirmModal/ConfirmModal";

const CashRegisterDetails = ({ currentCashRegister }) => {
  const hasEditPermissions = validateModuleAccess(MODIFY_LOUNGES_UUID, false);
  const location = useLocation();
  const navigate = useNavigate();
  const { idSede } = useParams();
  const { state } = location;
  const { cashregister, viewAction } = state;
  const printableRef = useRef(null);
  const [reloadRucs, setReloadRucs] = useState(null);

  const currentCashRegisterState = useAppSelector(
    (state) => state.listCashRegisters
  ).find((cash) => cash.idSaleBox === cashregister);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const headerContext: any = useContext(HeaderContext);

  const dispatch = useAppDispatch();
  const {
    useCreateCashRegister,
    useModifyCashRegister,
    useSetCashRegisterDocuments,
    useSetCashRegisterRuc,
    useSetDefaultCashRegisterRuc,
    useSetBillingInfo,
    useSetDefaultBillingInfo,
    useSetDepartamentos,
    useSetProvincias,
    useSetDistritos,
  } = useCashRegistersActions();

  const [promiseStatus, setPromiseStatus] = useState({
    billingInformation: { status: 0, data: null },
    billingDocuments: { status: 0, data: null },
    billingComment: { status: 0, data: null },
    rucList: { status: 0, data: null },
    departamentoList: { status: 0, data: null },
    provinciaList: { status: 0, data: null },
    distritoList: { status: 0, data: null },
  });

  // Estado para habilitar o deshabilitar la facturación electrónica
  const [digitalBillingEnabled, setDigitalBillingEnabled] = useState(false);

  // Estado para guardar los valores iniciales del formulario y luego poder comparar modificaciones
  const [editedFormValuesInitial, setEditedFormValuesInitial] = useState({
    nombreCaja: currentCashRegister?.saleBoxName,
    billingOptions: [],
    ruc: "",
    direccionFacturacion: "",
    otraInformacion: "",
    departamento: "",
    provincia: "",
    distrito: "",
  });

  // Estado para manejar los documentos de facturación seleccionados
  const [selectedBillingOptions, setSelectedBillingOptions] = useState([5]);

  // Estado para guardar los valores modificados del formulario
  const [editedFormValues, setEditedFormValues] = useState(
    editedFormValuesInitial
  );

  const [isVisibleModalAddRuc, setIsVisibleModalAddRuc] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // useEffect(() => {
  // 	(async () => {
  // 		if (reloadRucs) {
  // 			const rucList = await useSetDefaultCashRegisterRuc();
  // 			setPromiseStatus({
  // 				...promiseStatus,
  // 				rucList: {
  // 					status: rucList.status,
  // 					data: rucList.data,
  // 				},
  // 			});
  // 		}
  // 	})();
  // 	//eslint-disable-next-line
  // }, [reloadRucs]);

  // useEffect(() => {
  // 	if (reloadRucs) {
  // 		let ruc = reloadRucs;
  // 		let selectRuc = rucListNames.find((r) => r.includes(ruc));
  // 		setEditedFormValues((prevEditedFormValues) => ({
  // 			...prevEditedFormValues,
  // 			ruc: selectRuc,
  // 		}));
  // 		setReloadRucs(null);
  // 	}
  // }, [promiseStatus.rucList]);

  // Lista de opciones para RUC
  const rucListNames = promiseStatus.rucList.data?.map(
    (ruc) =>
      `${ruc.ruc} - ${ruc.razonSocial} (${
        ruc.estado === 2
          ? "Pendiente"
          : ruc.estado === 1
          ? "Autorizado"
          : "Rechazado"
      })`
  );

  const departamentoListNames = promiseStatus.departamentoList.data?.map(
    (departamento) => `${departamento.departamento}`
  );
  const provinciaListNames = promiseStatus.provinciaList.data?.map(
    (provincia) => `${provincia.provincia}`
  );
  const distritoListNames = promiseStatus.distritoList.data?.map(
    (distrito) => `${distrito.distrito}`
  );

  // UseEffect para obtener las listas de provincias cuando se selecciona un departamento
  const handleProvinciaRefreshList = async (event: {
    target: { name: string; value: string };
  }) => {
    setEditedFormValues((prevEditedFormValues) => ({
      ...prevEditedFormValues,
      provincia: "",
      distrito: "",
    }));

    const selectedDepartamentoId = promiseStatus.departamentoList.data?.find(
      (departamento) => departamento.departamento === event.target.value
    )?.idDepartamento;

    try {
      let provinciaList = { status: 0, data: null };

      if (selectedDepartamentoId) {
        //eslint-disable-next-line
        provinciaList = await useSetProvincias(selectedDepartamentoId);
      }

      setPromiseStatus({
        ...promiseStatus,
        provinciaList: {
          status: provinciaList.status,
          data: provinciaList.data,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const handleDistritoRefreshList = async (event: {
    target: { name: string; value: string };
  }) => {
    setEditedFormValues((prevEditedFormValues) => ({
      ...prevEditedFormValues,
      distrito: "",
    }));

    const selectedProvinciaId = promiseStatus.provinciaList.data?.find(
      (provincia) => provincia.provincia === event.target.value
    )?.idProvincia;

    try {
      let distritoList = { status: 0, data: null };

      if (selectedProvinciaId) {
        //eslint-disable-next-line
        distritoList = await useSetDistritos(selectedProvinciaId);
      }

      setPromiseStatus({
        ...promiseStatus,
        distritoList: {
          status: distritoList.status,
          data: distritoList.data,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  // Función para modificar el estado de los campos de los comprobantes de pago
  const handleBillingFieldChange = (
    event: { target: { name: string; value: string } },
    codigoComprobante: number,
    creditNoteDocumentId: number | null = null,
    serie?: String
  ) => {
    const { name, value } = event.target;
    setEditedFormValues((prevEditedFormValues) => ({
      ...prevEditedFormValues,
      billingOptions: prevEditedFormValues.billingOptions.map(
        (billingOption) => {
          if (
            billingOption.tipoDocumento === codigoComprobante &&
            !creditNoteDocumentId
          ) {
            switch (name) {
              case "serie":
                const formattedValue = !serie
                  ? value.length < 4
                    ? `${"0".repeat(3 - value.length)}${value}`
                    : value.slice(0, 3)
                  : serie.length < 4
                  ? `${"0".repeat(3 - serie.length)}${value}`
                  : serie.slice(0, 3);

                return {
                  ...billingOption,
                  serie: formattedValue,
                  numero: "00000001",
                };
              case "numero":
                return {
                  ...billingOption,
                  numero:
                    value.length < 9
                      ? `${"0".repeat(8 - value.length)}${value}`
                      : value.slice(0, 8),
                };
              default:
                return billingOption;
            }
          }

          if (
            billingOption.tipoDocumento === codigoComprobante &&
            billingOption.creditNoteDocumentId === creditNoteDocumentId
          ) {
            switch (name) {
              case "serie":
                const formattedValue = !serie
                  ? value.length < 4
                    ? `${"0".repeat(3 - value.length)}${value}`
                    : value.slice(0, 3)
                  : serie.length < 4
                  ? `${"0".repeat(3 - serie.length)}${value}`
                  : serie.slice(0, 3);

                return {
                  ...billingOption,
                  serie: formattedValue,
                  numero: "00000001",
                };
              case "numero":
                return {
                  ...billingOption,
                  numero:
                    value.length < 9
                      ? `${"0".repeat(8 - value.length)}${value}`
                      : value.slice(0, 8),
                };
              default:
                return billingOption;
            }
          }

          return billingOption;
        }
      ),
    }));
  };

  // Función para modificar el estado de los checkbox de los comprobantes de pago
  const handleChangeSelectedBillingOption = (codigoComprobante: number) => {
    if (digitalBillingEnabled) {
      const isCurrentlySelected =
        selectedBillingOptions.includes(codigoComprobante);
      const newSelectedOptions = isCurrentlySelected
        ? selectedBillingOptions.filter(
            (option) => option !== codigoComprobante
          )
        : [...selectedBillingOptions, codigoComprobante];

      // Si no quedarían opciones seleccionadas, no hacemos nada
      if (newSelectedOptions.length === 0) {
        return;
      }

      // Actualizamos el estado en editedFormValues
      setEditedFormValues((prevState) => ({
        ...prevState,
        billingOptions: prevState.billingOptions.map((billingOption) => {
          // Para documentos principales
          if (billingOption.tipoDocumento === codigoComprobante) {
            return {
              ...billingOption,
              activo: isCurrentlySelected ? 0 : 1,
            };
          }

          // Para notas de crédito
          if (billingOption.tipoDocumento === 3) {
            if (
              billingOption.creditNoteDocumentId === boletaVentaCodigo &&
              codigoComprobante === boletaVentaCodigo
            ) {
              return {
                ...billingOption,
                activo: isCurrentlySelected ? 0 : 1,
              };
            }
            if (
              billingOption.creditNoteDocumentId === facturaCodigo &&
              codigoComprobante === facturaCodigo
            ) {
              return {
                ...billingOption,
                activo: isCurrentlySelected ? 0 : 1,
              };
            }
          }

          return billingOption;
        }),
      }));

      setSelectedBillingOptions(newSelectedOptions);
    } else {
      setSelectedBillingOptions([notaVentaCodigo]);
      setEditedFormValues((prevState) => ({
        ...prevState,
        billingOptions: prevState.billingOptions.map((billingOption) => ({
          ...billingOption,
          activo: billingOption.tipoDocumento === notaVentaCodigo ? 1 : 0,
        })),
      }));
    }
  };

  const BillingOption = ({
    codigoComprobante,
    data,
    creditNoteDocumentId = null,
    defaultOption = false,
    required = false,
    disabled = false,
    isSeriesDisabled = false,
  }) => {
    let selectedOption = data.activo === 1;

    const nroComprobante =
      data.numero.length < 9
        ? `${"0".repeat(8 - data.numero.length)}${data.numero}`
        : typeof data.numero === "string" && data.numero.slice(0, 8);

    const [serie, setSerie] = useState(data.serie);
    return (
      <div
        className={`${style.billingInputRow} ${
          defaultOption && !digitalBillingEnabled
            ? style.billingCheckContainerDefault
            : ""
        }`}
      >
        <label
          className={`${style.billingCheckContainer} ${
            selectedOption ? style.billingUncheckContainer : ""
          } ${required ? style.billingCheckContainerRequired : ""}`}
        >
          <input
            type="checkbox"
            checked={selectedOption}
            onChange={
              !required
                ? () => handleChangeSelectedBillingOption(codigoComprobante)
                : null
            }
            disabled={disabled}
          />
          <span>{data.nombre}</span>
        </label>
        <div
          className={style.billingSerieContainer}
          onClick={() => setSerie("")}
        >
          <label className={style.billingSerieContent}>
            Serie{" "}
            <span className={style.billingInfoContainer}>
              <img src={UserInfoIcon} alt="i" />
              <span className={style.billingInfoTooltip}>
                El número de serie debe ser de uso exclusivo de esta caja, no
                puedes usarlo en otro lugar, dentro o fuera de Prikly.
              </span>
            </span>
          </label>
          <TextInput
            width={90}
            inputProps={{
              value: serie,
              onChange: (e) => handleBillingSerieChange(e, setSerie),
              onBlur: (e) =>
                handleBillingFieldChange(
                  e,
                  codigoComprobante,
                  creditNoteDocumentId,
                  serie
                ),
              placeholder: "000",
              name: "serie",
              disabled: isSeriesDisabled,
            }}
            disabled={isSeriesDisabled}
          />
        </div>
        <TextInput
          label="Último comprobante emitido"
          inputProps={{
            value: nroComprobante === "00000000" ? "00000001" : nroComprobante,
            onChange: (e) =>
              handleBillingFieldChange(
                e,
                codigoComprobante,
                creditNoteDocumentId
              ),
            placeholder: "--------",
            type: "number",
            name: "numero",
          }}
          disabled
        />
      </div>
    );
  };

  // UseEffect para obtener la información de la caja actual
  useEffect(() => {
    const fetchData = async () => {
      try {
        headerContext.handleIsHidden(false);

        let billingInformation = { status: 0, data: null };
        let documentsInformation: { status: number; data: any };
        let billingComment: { status: number; data: any };
        //eslint-disable-next-line
        let rucList = await useSetDefaultCashRegisterRuc();
        //eslint-disable-next-line
        let departamentoList = await useSetDepartamentos();
        let provinciaList = { status: 200, data: [] };
        let distritoList = { status: 200, data: [] };

        if (viewAction === "edit") {
          //eslint-disable-next-line
          documentsInformation = await useSetCashRegisterDocuments(
            cashregister
          );
          //eslint-disable-next-line
          billingComment = await useSetBillingInfo(cashregister);
          //eslint-disable-next-line
          billingInformation = await useSetCashRegisterRuc(cashregister);

          if (billingInformation.data[0]?.idDepartamento) {
            //eslint-disable-next-line
            provinciaList = await useSetProvincias(
              billingInformation.data[0]?.idDepartamento
            );
            //eslint-disable-next-line
            distritoList = await useSetDistritos(
              billingInformation.data[0]?.idProvincia
            );
          }
        } else {
          //eslint-disable-next-line
          const response = await useSetDefaultBillingInfo();
          documentsInformation = getDefaultBillingInfoAdapter(response);

          billingComment = {
            status: 200,
            data: [{ comentarioFacturacion: "" }],
          };
          billingInformation = {
            status: 200,
            data: [{ direccion: "", facturacionElectronica: 0 }],
          };
        }

        setPromiseStatus({
          ...promiseStatus,
          billingInformation: {
            status: billingInformation.status,
            data: billingInformation.data,
          },
          billingDocuments: {
            status: documentsInformation.status,
            data: documentsInformation.data,
          },
          billingComment: {
            status: billingComment?.status,
            data: billingComment?.data[0].comentarioFacturacion,
          },
          rucList: {
            status: rucList.status,
            data: rucList.data,
          },
          departamentoList: {
            status: departamentoList.status,
            data: departamentoList.data,
          },
          provinciaList: {
            status: provinciaList.status,
            data: provinciaList.data,
          },
          distritoList: {
            status: distritoList.status,
            data: distritoList.data,
          },
        });

        let rucFormat = "";
        let selectedDepartamento = "";
        let selectedProvincia = "";
        let selectedDistrito = "";

        if (billingInformation.data[0]?.ruc && rucList.data.length > 0) {
          const selectedRuc = rucList.data.find(
            (ruc) => ruc.ruc === billingInformation.data[0]?.ruc
          );
          if (selectedRuc) {
            rucFormat = `${selectedRuc.ruc} - ${selectedRuc.razonSocial} (${
              selectedRuc.estado === 2
                ? "Pendiente"
                : selectedRuc.estado === 1
                ? "Autorizado"
                : "Rechazado"
            })`;
          }
        }

        if (billingInformation.data[0]?.idDepartamento) {
          selectedDepartamento = departamentoList.data.find(
            (departamento) =>
              departamento.idDepartamento ===
              billingInformation.data[0]?.idDepartamento
          ).departamento;

          selectedProvincia = provinciaList.data.find(
            (provincia) =>
              provincia.idProvincia === billingInformation.data[0]?.idProvincia
          ).provincia;
          selectedDistrito = distritoList.data.find((distrito) => {
            return (
              distrito.idDistrito === billingInformation.data[0]?.idDistrito
            );
          }).distrito;
        }
        const initialValues = {
          ...editedFormValues,
          billingOptions: documentsInformation.data,
          direccionFacturacion: billingInformation.data[0]?.direccion,
          otraInformacion: billingComment?.data[0].comentarioFacturacion,
          ruc: rucFormat,
          departamento: selectedDepartamento,
          provincia: selectedProvincia,
          distrito: selectedDistrito,
        };

        setEditedFormValuesInitial(initialValues);
        setEditedFormValues(initialValues);
        setDigitalBillingEnabled(
          billingInformation.data[0]?.facturacionElectronica === 1
            ? true
            : false
        );

        // Nueva lógica para establecer selectedBillingOptions
        const selectedBillingOptions = initialValues.billingOptions
          .filter(
            (billingOption) =>
              // Solo incluimos documentos principales que estén activos
              billingOption.activo === 1 &&
              // Excluimos las notas de crédito (tipo 3)
              billingOption.tipoDocumento !== 3
          )
          .map((billingOption) => billingOption.tipoDocumento);

        setSelectedBillingOptions(selectedBillingOptions);
      } catch (error) {
        console.warn(error);
        setPromiseStatus({
          ...promiseStatus,
          billingDocuments: { status: 401, data: null },
          billingInformation: { status: 401, data: null },
          billingComment: { status: 401, data: null },
          rucList: { status: 401, data: null },
          departamentoList: { status: 401, data: null },
        });
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, [cashregister]);

  // Si la caja está en downgrade, redirigir al listado
  useEffect(() => {
    if (currentCashRegister?.isDowngraded) {
      navigate(`/${idSede}`, { state: {} });
      dispatch(
        updateNotificationMessage(
          "Esta caja está deshabilitada debido a la cantidad de cajas disponibles en tu plan actual"
        )
      );
      dispatch(updateNotificationStatus(400));
    }
  }, [currentCashRegister, navigate, idSede, dispatch]);

  // Si la caja está en downgrade, no renderizar nada
  if (currentCashRegister?.isDowngraded) {
    return null;
  }

  const createAction = async () => {
    headerContext.handleIsLoading(true);
    headerContext.handleIsDisabled(true);

    const newOptions = editedFormValues.billingOptions.map((billingOption) => {
      return {
        idVoucherType: billingOption.tipoDocumento,
        serialNumber: billingOption.serie,
        number:
          billingOption.numero === "0" ? 1 : parseInt(billingOption.numero),
        activeBit:
          billingOption.activo || billingOption.creditNoteDocumentId ? 1 : 0,
        creditNoteIdVoucherType: billingOption.creditNoteDocumentId,
      };
    });

    let rucId = "";
    let selectedRuc = { idCertificado: 0, razonSocial: "" };
    let distritoId = 0;

    if (digitalBillingEnabled) {
      rucId = editedFormValues.ruc.split(" - ")[0];
      selectedRuc = promiseStatus.rucList.data?.find(
        (ruc) => ruc.ruc === rucId
      ) || {
        idCertificado: 0,
        razonSocial: "",
      };
      distritoId = promiseStatus.distritoList.data?.find(
        (distrito) => distrito.distrito === editedFormValues.distrito
      )?.idDistrito;
    }

    try {
      const data = {
        nameSaleBox: editedFormValues.nombreCaja,
        billingElectronic: digitalBillingEnabled ? 1 : 0,
        idBillingElectronicCertificate: selectedRuc?.idCertificado,
        ruc: rucId,
        businessName: selectedRuc.razonSocial,
        title_LoungeName: selectedRuc.razonSocial,
        subtitle_LoungeName: selectedRuc.razonSocial,
        addressLine1: editedFormValues.direccionFacturacion || "",
        additionalInformation: editedFormValues.otraInformacion || "",
        idDistrict: distritoId,
        visible: 0,
        mobile: 0,
        voucherTypes: newOptions,
      };

      //eslint-disable-next-line
      const response = await useCreateCashRegister(data);

      if (response.status === 200) {
        dispatch(updateNotificationMessage("Caja creada con éxito"));
        dispatch(updateNotificationStatus(200));
        setEditedFormValuesInitial(editedFormValues);
        navigate(`/${idSede}`, { state: {} });
      } else {
        dispatch(updateNotificationMessage(response.message));
        dispatch(updateNotificationStatus(400));
      }
      headerContext.handleIsLoading(false);
    } catch (error) {
      headerContext.handleIsLoading(false);
      dispatch(
        updateNotificationMessage(
          "Hubo un error. Debes completar todos los campos obligatorios"
        )
      );
      dispatch(updateNotificationStatus(400));
    }
  };

  const editAction = async () => {
    headerContext.handleIsLoading(true);
    headerContext.handleIsDisabled(true);

    const newOptions = editedFormValues.billingOptions.map((billingOption) => {
      return {
        idTipoComprobante: billingOption.tipoDocumento,
        numeroSerie: billingOption.serie,
        numero:
          billingOption.numero === "0" ? 1 : parseInt(billingOption.numero),
        activo: billingOption.activo,
        creditNoteVoucherId: billingOption.creditNoteDocumentId,
      };
    });

    let rucId = "";
    let selectedRuc = { idCertificado: 0, razonSocial: "" };
    let distritoId = 0;

    if (digitalBillingEnabled) {
      rucId = editedFormValues.ruc.split(" - ")[0];
      selectedRuc = promiseStatus.rucList.data?.find(
        (ruc) => ruc.ruc === rucId
      );
      distritoId = promiseStatus.distritoList.data?.find(
        (distrito) => distrito.distrito === editedFormValues.distrito
      )?.idDistrito;
    }

    try {
      const data = {
        nameSaleBox: editedFormValues.nombreCaja,
        billingElectronic: digitalBillingEnabled ? 1 : 0,
        idBillingElectronicCertificate: selectedRuc?.idCertificado,
        ruc: rucId,
        businessName: selectedRuc.razonSocial,
        title_LoungeName: selectedRuc.razonSocial,
        subtitle_LoungeName: selectedRuc.razonSocial,
        addressLine1: editedFormValues.direccionFacturacion || "",
        additionalInformation: editedFormValues.otraInformacion || "",
        idDistrict: distritoId,
        visible: currentCashRegister?.visible || 0,
        mobile: 0,
        voucherTypes: newOptions.map((option) => ({
          idVoucherType: option.idTipoComprobante,
          serialNumber: option.numeroSerie,
          number: Number(option.numero),
          activeBit: option.activo || option.creditNoteVoucherId ? 1 : 0,
          creditNoteIdVoucherType: option.creditNoteVoucherId,
        })),
      };

      //eslint-disable-next-line
      const response = await useModifyCashRegister(
        data,
        currentCashRegisterState,
        cashregister
      );

      if (response.status === 200) {
        dispatch(updateNotificationMessage("Caja modificada con éxito"));
        dispatch(updateNotificationStatus(200));
        setEditedFormValuesInitial(editedFormValues);
        setShowConfirmModal(false);
      } else {
        dispatch(updateNotificationMessage(response.message));
        dispatch(updateNotificationStatus(400));
      }
      headerContext.handleIsLoading(false);
    } catch (error) {
      headerContext.handleIsLoading(false);
      dispatch(updateNotificationMessage("Error al actualizar la información"));
      dispatch(updateNotificationStatus(400));
    }
  };

  // Función para verificar si se modificaron los documentos o series
  const hasDocumentChanges = () => {
    // Si no hay valores iniciales, no hay cambios
    if (
      !editedFormValuesInitial.billingOptions ||
      !editedFormValues.billingOptions
    ) {
      return false;
    }

    return editedFormValues.billingOptions.some((currentOption) => {
      const initialOption = editedFormValuesInitial.billingOptions.find(
        (opt) =>
          opt.tipoDocumento === currentOption.tipoDocumento &&
          opt.creditNoteDocumentId === currentOption.creditNoteDocumentId
      );

      if (!initialOption) return true;

      // Verificar cambios en activo o serie
      return (
        currentOption.activo !== initialOption.activo ||
        currentOption.serie !== initialOption.serie
      );
    });
  };

  // Modificar las funciones de acción
  const handleHeaderAction = () => {
    if (hasDocumentChanges()) {
      setShowConfirmModal(true);
    } else {
      // Si no hay cambios en documentos, ejecutar la acción directamente
      viewAction === "edit" ? editAction() : createAction();
    }
  };

  // UseEffect para validar y subir cambios en el formulario y habilitar o deshabilitar el botón de guardar
  useEffect(() => {
    const initialDigitalBilling =
      promiseStatus.billingInformation.data !== null &&
      promiseStatus.billingInformation.data[0]?.facturacionElectronica === 1
        ? true
        : false;

    if (
      JSON.stringify(editedFormValues) !==
        JSON.stringify(editedFormValuesInitial) ||
      digitalBillingEnabled !== initialDigitalBilling
    ) {
      headerContext.handleIsDisabled(false);
      headerContext.handleClickChange(handleHeaderAction);
    } else {
      headerContext.handleIsDisabled(true);
    }
    //eslint-disable-next-line
  }, [
    editedFormValues,
    editedFormValuesInitial,
    digitalBillingEnabled,
    promiseStatus.billingInformation,
  ]);

  // Documentos separados por tipo
  const notaVentaCodigo = 5;
  const defaultNotaVenta = editedFormValues?.billingOptions?.find(
    (billingOption) => billingOption.tipoDocumento === notaVentaCodigo
  );

  const boletaVentaCodigo = 1;
  const defaultBoletaVenta = editedFormValues?.billingOptions?.find(
    (billingOption) => billingOption.tipoDocumento === boletaVentaCodigo
  );

  const facturaCodigo = 2;
  const defaultFactura = editedFormValues?.billingOptions?.find(
    (billingOption) => billingOption.tipoDocumento === facturaCodigo
  );

  const creditoBoletaCodigo = 3;
  const defaultCreditoBoleta = editedFormValues?.billingOptions?.find(
    (billingOption) =>
      billingOption.tipoDocumento === creditoBoletaCodigo &&
      billingOption.creditNoteDocumentId === boletaVentaCodigo
  );

  const creditoFacturaCodigo = 3;
  const defaultCreditoFactura = editedFormValues?.billingOptions?.find(
    (billingOption) =>
      billingOption.tipoDocumento === creditoFacturaCodigo &&
      billingOption.creditNoteDocumentId === facturaCodigo
  );

  const getDocumentSerie = (serie) => {
    const temp = serie?.replace(/\D/g, "");
    return temp.length < 4
      ? `${"0".repeat(3 - temp.length)}${temp}`
      : temp.slice(0, 3);
  };

  // Pantalla de carga
  if (
    promiseStatus.billingDocuments.status === 0 ||
    promiseStatus.billingInformation.status === 0 ||
    promiseStatus.billingComment.status === 0
  ) {
    return (
      <div className={style.loadingContainer}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </div>
    );
  }

  return (
    <div className={style.viewContainer}>
      <h3 className={style.sectionTitle}>Información general</h3>
      <div className={style.inputContainer}>
        <TextInput
          label="Nombre de la caja"
          flex={1}
          required
          inputProps={{
            value: editedFormValues.nombreCaja,
            onChange: (e) => handleFieldChange(e, setEditedFormValues),
            placeholder: "Nombre",
            type: "text",
            name: "nombreCaja",
            disabled: !hasEditPermissions,
          }}
        />
      </div>
      <div className={style.digitalBillingContainer}>
        <h3 className={style.sectionTitle} style={{ marginRight: 20 }}>
          Facturación electrónica
        </h3>
        <div className={style.billingOptionsContainer}>
          <p className={style.billingOption}>No</p>
          <SwitchInput
            value={digitalBillingEnabled}
            handleChange={setDigitalBillingEnabled}
            disabled={!hasEditPermissions}
          />
          <p className={style.billingOption}>Si</p>
        </div>
      </div>
      <FormAuthorizeRuc
        isVisible={isVisibleModalAddRuc}
        setIsVisible={setIsVisibleModalAddRuc}
        onClick={(result) => {
          if (result.isSuccessful) {
            setReloadRucs(result.result.rucData.ruc);
          }
        }}
      />
      <div
        className={`${style.optionsContainer} ${
          digitalBillingEnabled ? style.optionsContianerActive : ""
        }`}
      >
        <SelectInput
          label={
            <p className={style.rucLabel}>
              Seleccione el RUC y razón social autorizados para facturación
              electrónica -{" "}
              <button
                // href={`${window.location.origin}/facturacion`}
                onClick={() => setIsVisibleModalAddRuc(true)}
                className={style.rucLabelRedirect}
                disabled={!hasEditPermissions}
              >
                Para autorizar un nuevo RUC haz click aquí
              </button>
            </p>
          }
          value={editedFormValues.ruc}
          /* touched={touched.pais}
          error={errors.pais} */
          handleChange={(e) => handleFieldChange(e, setEditedFormValues)}
          keyboardType={"text"}
          name={"ruc"}
          flex={1}
          items={rucListNames}
          required
          disabled={!hasEditPermissions}
        />

        <SelectInput
          label={"Departamento"}
          value={editedFormValues.departamento}
          handleChange={(e) => {
            handleFieldChange(e, setEditedFormValues),
              handleProvinciaRefreshList(e);
          }}
          keyboardType={"text"}
          name={"departamento"}
          flex={1}
          items={departamentoListNames}
          disabled={!hasEditPermissions}
        />
        <SelectInput
          label={"Provincia"}
          value={editedFormValues.provincia}
          handleChange={(e) => {
            handleFieldChange(e, setEditedFormValues),
              handleDistritoRefreshList(e);
          }}
          keyboardType={"text"}
          name={"provincia"}
          flex={1}
          items={provinciaListNames}
          disabled={!hasEditPermissions}
        />
        <SelectInput
          label={"Distrito"}
          value={editedFormValues.distrito}
          handleChange={(e) => handleFieldChange(e, setEditedFormValues)}
          keyboardType={"text"}
          name={"distrito"}
          flex={1}
          items={distritoListNames}
          required
          disabled={!hasEditPermissions}
        />
        <TextInput
          label="Dirección de facturación (Aparecerá en tu comprobante)"
          flex={1}
          inputProps={{
            value: editedFormValues.direccionFacturacion,
            onChange: (e) => handleFieldChange(e, setEditedFormValues),
            placeholder: "Escriba aquí...",
            type: "text",
            name: "direccionFacturacion",
            disabled: !hasEditPermissions,
          }}
          required
        />
        {digitalBillingEnabled && (
          <>
            <div className={style.documentGroupContainer}>
              <BillingOption
                codigoComprobante={boletaVentaCodigo}
                data={{
                  nombre: "Boleta de Venta",
                  serie: defaultBoletaVenta?.serie || "001",
                  numero: defaultBoletaVenta?.numero || "1",
                  activo: defaultBoletaVenta?.activo || 0,
                }}
                disabled={!hasEditPermissions}
              />
              {selectedBillingOptions.includes(boletaVentaCodigo) && (
                <div className={style.creditNoteContainer}>
                  <div className={style.creditNoteContent}>
                    <BillingOption
                      codigoComprobante={creditoBoletaCodigo}
                      creditNoteDocumentId={boletaVentaCodigo}
                      data={{
                        nombre: "Nota de Crédito (B)",
                        serie: defaultCreditoBoleta?.serie || "001",
                        numero: defaultCreditoBoleta?.numero || "1",
                        activo: defaultCreditoBoleta?.activo || 0,
                      }}
                      required={true}
                      disabled={true}
                      isSeriesDisabled={false}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={style.documentGroupContainer}>
              <BillingOption
                codigoComprobante={facturaCodigo}
                data={{
                  nombre: "Factura",
                  serie: defaultFactura?.serie || "001",
                  numero: defaultFactura?.numero || "1",
                  activo: defaultFactura?.activo || 0,
                }}
                disabled={!hasEditPermissions}
              />
              {selectedBillingOptions.includes(facturaCodigo) && (
                <div className={style.creditNoteContainer}>
                  <div className={style.creditNoteContent}>
                    <BillingOption
                      codigoComprobante={creditoFacturaCodigo}
                      creditNoteDocumentId={facturaCodigo}
                      data={{
                        nombre: "Nota de Crédito (F)",
                        serie: defaultCreditoFactura?.serie || "001",
                        numero: defaultCreditoFactura?.numero || "1",
                        activo: defaultCreditoFactura?.activo || 0,
                      }}
                      required={true}
                      disabled={true}
                      isSeriesDisabled={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {defaultNotaVenta && (
        <BillingOption
          codigoComprobante={defaultNotaVenta.tipoDocumento}
          data={{
            nombre: defaultNotaVenta.nombreDocumento,
            serie: getDocumentSerie(defaultNotaVenta.serie),
            numero: defaultNotaVenta.numero,
            activo: defaultNotaVenta.activo,
          }}
          required={
            !digitalBillingEnabled ||
            (!selectedBillingOptions.includes(boletaVentaCodigo) &&
              !selectedBillingOptions.includes(facturaCodigo))
          }
          defaultOption
          disabled={!hasEditPermissions}
        />
      )}

      <div
        className={`${style.printingActionsContainer} ${
          digitalBillingEnabled ? style.printingActionsContainerActive : ""
        }`}
      >
        <ButtonWithoutIcon
          textBttn="Imprimir ticket de prueba"
          handleClick={handlePrint}
          width={"100%"}
        />
      </div>

      <h3 className={style.sectionTitle}>Otra Información</h3>
      <TextAreaInput
        label="Información adicional que aparecerá en el comprobante"
        height={100}
        textareaProps={{
          placeholder: "Escriba aquí...",
          name: "otraInformacion",
          value: editedFormValues.otraInformacion,
          onChange: (e) => handleFieldChange(e, setEditedFormValues),
          style: {
            resize: "vertical",
          },
          disabled: !hasEditPermissions,
        }}
      />

      <div style={{ display: "none" }}>
        <GeneratePrintableHTML
          reference={printableRef}
          billingData={billingPrintbuilder({
            titleName: editedFormValues.nombreCaja,
            business: {
              NAME:
                editedFormValues.ruc.split(" - ")[1] || "Nombre del negocio",
              RUC: editedFormValues.ruc.split(" - ")[0] || "00000000000",
            },
            local: {
              NAME: "Nombre del local",
              LOCATION: editedFormValues.direccionFacturacion,
              PHONE: "(000) 0000-0000",
              EMAIL: "correoelectronico@empresa.com",
            },
            billingCode: "F000-00000000",
            billingDocument: "FACTURA",
            products: [
              {
                DESCRIPTION: "Producto a facturar (fact. electronica)",
                AMOUNT: 1,
                TOTAL: 240,
              },
            ],
            client: {
              NAME: "Hugo Perez",
              PASSPORT: "000000000000",
              LOCATION: "Calle 12 Lima, Perú",
            },
          })}
        />
      </div>

      <ConfirmModal
        title="Confirmar cambios en documentos"
        message="Has realizado cambios en la selección de documentos o en sus números de serie. Estos cambios afectarán la forma en que se emiten los comprobantes. ¿Estás seguro de continuar?"
        buttonLbl="Confirmar"
        controller={{
          visible: showConfirmModal,
          setVisible: setShowConfirmModal,
        }}
        confirmAction={async () => {
          return viewAction === "edit"
            ? await editAction()
            : await createAction();
        }}
        data={null}
      />
    </div>
  );
};

export default CashRegisterDetails;
