// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TQbQkUV0RCUm6n1hKLW9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  border: 1px solid #937cf4;
  padding: 20px 30px;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/schedulesContainer/SchedulesContainer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,WAAW;EACX,SAAS;EACT,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".schedulesContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: 100%;\n  gap: 20px;\n  border: 1px solid #937cf4;\n  padding: 20px 30px;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedulesContainer": `TQbQkUV0RCUm6n1hKLW9`
};
export default ___CSS_LOADER_EXPORT___;
