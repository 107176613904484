export const convertIndexToTime = (index) => {
  // Validate that the index is in a valid range (0-24)
  if (index < 0 || index > 24) {
    throw new Error("El índice debe estar en el rango de 0 a 24");
  }

  const hour =
    Math.floor(index) <= 12 ? Math.floor(index) : Math.floor(index) - 12;
  const minutes = getMinutes(index);
  const period = index < 12 ? "AM" : "PM";

  const zeroPaddedHour = hour.toString().padStart(2, "0");

  return `${zeroPaddedHour}:${minutes} ${period}`;
};

const getMinutes = (index) => {
  switch (index % 1) {
    case 0:
      return "00";
    case 0.25:
      return "15";
    case 0.5:
      return "30";
    case 0.75:
      return "45";
    default:
      throw new Error("Los minutos no son válidos");
  }
};
