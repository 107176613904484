import { useEffect, useState } from "react";

// Components
//@ts-ignore
import { HeaderNavigation, CriticalErrorPage } from "@viuti/recursos";
import SitesMenuContainer from "@Components/molecules/sitesMenuContainer/sitesMenuContainer";

// Redux
import { useSitesAction } from "@Hooks/useSitesActions";
import { useLocation } from "react-router-dom";

// Styles
import style from "../siteDetails/siteDetails.module.css";

// Icons
import { LoadingPulseIcon } from "@Models/icons";

const SitesPage = () => {
  const { useSetSites } = useSitesAction();

  const location = useLocation();
  const { state } = location;

  const [promiseStatus, setPromiseStatus] = useState({
    sites: { status: 0, data: null },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        //eslint-disable-next-line
        const sitesData = await useSetSites();

        setPromiseStatus({
          sites: { status: sitesData.status, data: sitesData.data },
        });
      } catch (error) {
        setPromiseStatus({
          sites: { status: 401, data: null },
        });
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  const headerProps = {
    title: "Mis sedes",
    previousAction: null,
    previousActionMovement: "close",
    buttonProps: {
      textBttn: "Añadir sede",
      handleClick: () => {},
      isDisabled: false,
      isPrimary: true,
      isHidden: true,
    },
  };

  const ErrorView = ({ children }) => {
    return (
      <div className={style.errorViewContainer}>
        <h1 className={style.primaryErrorMsg}>{children}</h1>
        <h2 className={style.secondaryErrorMsg}>
          Por favor, intente más tarde.
        </h2>
      </div>
    );
  };

  if (promiseStatus.sites.status === 0) {
    return (
      <div className={style.loadingContainer}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </div>
    );
  }
  return (
    <>
      <HeaderNavigation {...headerProps} />
      {promiseStatus.sites.status === 200 ? (
        <SitesMenuContainer items={promiseStatus.sites.data} />
      ) : (
        <CriticalErrorPage />
      )}
    </>
  );
};

export default SitesPage;
