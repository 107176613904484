import { activeCashRegister } from "@ReduxService/states/cashRegisterSlice";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { activeCashService } from "@Services/activeCashService/activeCashService";

export const onActiveLocal = async (
  cashId: number,
  dispatch,
  setIsDeactivating: Function
) => {
  setIsDeactivating(true);
  const ACTIVE_BIT = 1;

  const backendRequestBody = {
    idSaleBox: cashId,
    visible: ACTIVE_BIT,
  };

  const backendResponse = await activeCashService(backendRequestBody);

  // ERROR PATH
  if (!backendResponse.isSuccess) {
    dispatch(updateNotificationMessage(backendResponse.errorMessage));
    dispatch(updateNotificationStatus(400));
    setIsDeactivating(false);
    return;
  }

  // SUCCESS PATH
  dispatch(activeCashRegister(cashId));
  dispatch(updateNotificationMessage(backendResponse.successMessage));
  dispatch(updateNotificationStatus(200));
  setIsDeactivating(false);
};
