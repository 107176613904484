import axios from "axios";
import { apiDomain, getConfig } from "../api";

const INACTIVE_URL = `${apiDomain}/caja/v1/SaleBox/DisabledSaleBox`;

export const activeCashService = async (fetchBody) => {
  const fetchConfig = getConfig();

  try {
    await axios.put(INACTIVE_URL, fetchBody, fetchConfig);
    return {
      isSuccess: true,
      successMessage: "La caja fue activada con éxito.",
    };
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage:
        err.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
    };
  }
};
