/**
 * The function `handleFieldChange` is used to update the `editedFormValues` state in a React
 * component when a form field value changes.
 * @param event - The event parameter is an object that represents the event that triggered the field
 * change. It contains information about the event, such as the target element that triggered the
 * event and the value of the target element.
 */
export const handleFieldChange = (
  event: { target: { name: string; value: string } },
  setChange: Function
) => {
  const { name, value } = event.target;
  setChange((prev: any) => ({
    ...prev,
    [name]: value,
  }));
};

/**
 * The function `handleFieldBlur` is used to handle the blur event on input fields and perform
 * validation based on the field name.
 * @param event - The `event` parameter is an object that represents the event that triggered the
 * field blur. It contains a `target` property, which is an object representing the element that
 * triggered the event. The `target` object has two properties: `name` and `value`.
 */
export const handleFieldBlur = (
  event: { target: { name: string; value: string } },
  setTouched: Function,
  validationSchema: Function
) => {
  const { name, value } = event.target;
  setTouched((prev: any) => ({ ...prev, [name]: true }));
  validationSchema(name, value);
};
