export const defaultBillingDocuments = [
  {
    activo: 0,
    nombreDocumento: "Boleta de Venta",
    numero: "1",
    serie: "001",
    tipoDocumento: 1,
    creditNoteDocumentId: null,
  },
  {
    activo: 0,
    nombreDocumento: "Nota de Crédito (B)",
    numero: "1",
    serie: "001",
    tipoDocumento: 3,
    creditNoteDocumentId: 1,
  },
  {
    activo: 0,
    nombreDocumento: "Factura",
    numero: "1",
    serie: "001",
    tipoDocumento: 2,
    creditNoteDocumentId: null,
  },
  {
    activo: 0,
    nombreDocumento: "Nota de Crédito (F)",
    numero: "1",
    serie: "001",
    tipoDocumento: 3,
    creditNoteDocumentId: 2,
  },
  {
    activo: 1,
    nombreDocumento: "Nota de venta",
    numero: "1",
    serie: "001",
    tipoDocumento: 5,
    creditNoteDocumentId: null,
  },
];
