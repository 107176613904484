import { useAppDispatch, useAppSelector } from "./store";

import {
  getApiSiteData,
  getApiSchedules,
  updateApiSchedules,
  addApiSchedules,
  deleteApiSchedules,
  getApiCashRegisters,
} from "@Services/sites";

// @ts-ignore
import { trackUserAction } from "@viuti/recursos";
import { updateSites } from "@ReduxService/states/sitesSlice";
import { Site, updateCurrentSite } from "@ReduxService/states/currentSiteSlice";
import { updateSiteSchedules } from "@ReduxService/states/siteSchedulesSlice";
import {
  CashRegister,
  updateCashRegisters,
} from "@ReduxService/states/cashRegisterSlice";
import { getApiSitesAdapter } from "../adapters/getApiSites/getApiSites.adapter";
import {
  getApiSiteDataAdapter,
  getApiSchedulesAdapter,
  getApiCashRegistersAdapter,
} from "../adapters/getApiSiteData/getApiSiteData.adapter";
import { getPlanConfigAdapter } from "../adapters/getPlanConfig/getPlanConfig.adapter";
import { updatePlanConfig } from "@ReduxService/states/planConfigSlice";

export const useSitesAction = () => {
  const dispatch = useAppDispatch();

  const useGetSites = () => {
    return useAppSelector((state) => state.listSites);
  };

  const useSetSites = async () => {
    const response = await getApiSitesAdapter();

    if (response.isSuccess) {
      dispatch(updateSites(response.data));
    } else {
      dispatch(updateSites([]));
    }

    return response;
  };

  const useSetCurrentSite = async (idSite: number) => {
    try {
      const response = await getApiSiteDataAdapter(idSite);

      if (response?.status === 200) {
        const newData: Site = {
          idLounge: response.data[0].idlocal,
          idCompany: response.data[0].idempresa,
          nameLounge: response.data[0].nombre,
          address: response.data[0].direccion,
          webSite: response.data[0].sitioWeb,
          dataCountry: {
            idCountry: response.data[0].paisData.idPais,
            nameCountry: response.data[0].paisData.nombrePais,
          },
          departament: response.data[0].departamento,
        };

        dispatch(updateCurrentSite(newData));
      } else {
        dispatch(
          updateCurrentSite({
            idLounge: 0,
            idCompany: 0,
            nameLounge: "",
            address: "",
            webSite: "",
            dataCountry: {
              idCountry: 1,
              nameCountry: "PERU",
            },
            departament: "",
          })
        );
      }
      return response;
    } catch (error) {
      console.error("Error fetching site data:", error);
      return null; // Indicar que ocurrió un error
    }
  };

  const useGetCurrentSite = () => {
    return useAppSelector((state) => state.currentSite);
  };

  const useSetSchedules = async (id: number) => {
    const defaultSchedules = [
      {
        idcodigodia: 1,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
      {
        idcodigodia: 2,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
      {
        idcodigodia: 3,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
      {
        idcodigodia: 4,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
      {
        idcodigodia: 5,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
      {
        idcodigodia: 6,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
      {
        idcodigodia: 7,
        apertura: "",
        cierre: "",
        desactivado: 1,
      },
    ];

    function combineSchedules(defaultSchedules, response) {
      const schedules = [...defaultSchedules]; // Crear una copia de defaultSchedules

      response.forEach((responseItem) => {
        // Buscar todos los elementos en schedules con el mismo idcodigodia
        const matchingReponse = schedules.find((schedule) => {
          return schedule.idcodigodia === responseItem.idcodigodia;
        });

        // Si no hay elementos en schedules con el mismo idcodigodia, agregar el elemento de response a schedules
        if (matchingReponse.apertura !== "") {
          schedules.push(responseItem);
        } else {
          // Si hay elementos en schedules con el mismo idcodigodia, reemplazar el elemento de schedules con el elemento de response
          schedules.forEach((schedule, index) => {
            if (schedule.idcodigodia === responseItem.idcodigodia) {
              schedules[index] = responseItem;
            }
          });
        }
      });

      return schedules;
    }

    try {
      let response = await getApiSchedulesAdapter(id);
      const combinedSchedules = combineSchedules(
        defaultSchedules,
        response.data
      );

      if (response?.status === 200) {
        dispatch(updateSiteSchedules(combinedSchedules));
      } else {
        dispatch(updateSiteSchedules([]));
      }
      response.data = combinedSchedules;
      return response;
    } catch (error) {
      console.error("Error fetching schedules:", error);
      return []; // Indicar que ocurrió un error
    }
  };

  const useGetSchedules = () => {
    return useAppSelector((state) => state.siteSchedules);
  };

  const useUpdateSchedules = async (idSite: number, schedules: any) => {
    if (schedules.length === 0) {
      return {
        status: 200,
      };
    }
    try {
      const edita_horarios = 37;
      trackUserAction(edita_horarios);
      // Crea un arreglo de promesas de actualización y ejecútalas
      const updatePromises = schedules.map(async (schedule: any) => {
        const scheduleData = {
          idLounge: schedule.idlocal,
          idSchedule: schedule.idHorario,
          codeDay: schedule.idcodigodia,
          opening: schedule.apertura,
          closing: schedule.cierre,
          activeBit: schedule.desactivado,
        };

        return await updateApiSchedules(scheduleData);
      });

      // Espera a que todas las promesas de actualización se completen
      const updates = await Promise.all(updatePromises);
      let successUpdate = true;
      updates.map((update: any) => {
        if (update?.status !== 200) {
          successUpdate = false;
        }
      });

      const response = await getApiSchedulesAdapter(idSite);

      if (successUpdate && response.status === 200) {
        dispatch(updateSiteSchedules(response.data));
        return response;
      } else {
        dispatch(updateSiteSchedules([]));
        return []; // Indicar que no se pudieron obtener horarios
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
      return []; // Indicar que ocurrió un error
    }
  };

  const useAddSchedules = async (idSite: number, schedules: any) => {
    if (schedules.length === 0) {
      return {
        status: 200,
      };
    }
    try {
      // Crea un arreglo de promesas de actualización y ejecútalas
      const updatePromises = schedules.map(async (schedule: any) => {
        const scheduleData = {
          idLounge: idSite,
          codeDay: schedule.idcodigodia,
          opening: schedule.apertura,
          closing: schedule.cierre,
          desabled: schedule.desactivado,
          idSchedule: schedule.idSchedule,
        };
        return await addApiSchedules(scheduleData);
      });

      // Espera a que todas las promesas de actualización se completen
      const updates = await Promise.all(updatePromises);
      let successUpdate = true;
      updates.map((update: any) => {
        if (update?.status !== 200) {
          successUpdate = false;
        }
      });

      // Después de que todas las actualizaciones se completen, obtén los horarios actualizados
      const response = await getApiSchedulesAdapter(idSite);

      if (successUpdate && response.status === 200) {
        dispatch(updateSiteSchedules(response.data));
        return response;
      } else {
        dispatch(updateSiteSchedules([]));
        return []; // Indicar que no se pudieron obtener horarios
      }
    } catch (error) {
      //console.error("Error fetching schedules:", error);
      return []; // Indicar que ocurrió un error
    }
  };

  const useDeleteSchedules = async (idSite: number, schedules: any) => {
    if (schedules.length === 0) {
      return {
        status: 200,
      };
    }
    try {
      // Crea un arreglo de promesas de actualización y ejecútalas
      const updatePromises = schedules.map(async (schedule: any) => {
        return await deleteApiSchedules(schedule.idHorario);
      });

      // Espera a que todas las promesas de actualización se completen
      const updates = await Promise.all(updatePromises);
      let successUpdate = true;
      updates.map((update: any) => {
        if (update?.status !== 200) {
          successUpdate = false;
        }
      });

      // Después de que todas las actualizaciones se completen, obtén los horarios actualizados
      const response = await getApiSchedulesAdapter(idSite);

      if (successUpdate && response.status === 200) {
        dispatch(updateSiteSchedules(response.data));
        return response;
      } else {
        dispatch(updateSiteSchedules([]));
        return []; // Indicar que no se pudieron obtener horarios
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
      return []; // Indicar que ocurrió un error
    }
  };

  const useGetCashRegisters = () => {
    return useAppSelector((state) => state.listCashRegisters);
  };

  const useSetCashRegisters = async (idSite: number) => {
    try {
      const response = await getApiCashRegistersAdapter(idSite);

      if (response?.status === 200) {
        dispatch(updateCashRegisters(response.data));
      } else {
        dispatch(updateCashRegisters([]));
      }
      return response;
    } catch (error) {
      console.error("Error fetching schedules:", error);
      return []; // Indicar que ocurrió un error
    }
  };

  const useGetCashRegisterDetails = (id: number) => {
    try {
      const allCashRegisters = useGetCashRegisters();
      const cashregister = allCashRegisters.find(
        (cashregister) => cashregister.idSaleBox === id
      );

      const defaultCashRegister: CashRegister = {
        idSaleBox: 0,
        saleBoxName: "",
        open: 0,
        active: 0,
      };

      return cashregister || defaultCashRegister;
    } catch (error) {
      //("useGetCashRegisterDetails -> error", error);
    }
  };

  const useSetPlanConfig = async () => {
    try {
      const response = await getPlanConfigAdapter();

      if (response?.status === 200) {
        dispatch(updatePlanConfig(response.data));
      }
      return response;
    } catch (error) {
      console.error("Error fetching plan config:", error);
      return {
        status: 500,
        data: null,
      };
    }
  };

  const useGetPlanConfig = () => {
    return useAppSelector((state) => state.planConfig);
  };

  return {
    useGetSites,
    useSetSites,
    useSetCurrentSite,
    useGetCurrentSite,
    useGetSchedules,
    useSetSchedules,
    useAddSchedules,
    useDeleteSchedules,
    useUpdateSchedules,
    useGetCashRegisters,
    useSetCashRegisters,
    useGetCashRegisterDetails,
    useSetPlanConfig,
    useGetPlanConfig,
  };
};
