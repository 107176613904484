import { useState, useEffect } from "react";

// Components
import {
  SlideNotification,
  ButtonWithoutIcon,
  validateModuleAccess,
  //@ts-ignore
} from "@viuti/recursos";
import SitesMenuContainer from "@Components/molecules/sitesMenuContainer/sitesMenuContainer";
import ColumnNavigationGuide from "@Components/molecules/columnNavigationGuide/columnNavigationGuide";

// Views
import CashRegisterDetails from "@Pages/cashRegisterDetails/cashRegisterDetails";
import CashRegisterMovementsTypes from "@Pages/movementsTypes/movementsTypes";
import CashRegisterPaymentMethods from "@Pages/paymentMethods/paymentMethods";

// Styles
import style from "./siteCashRegister.module.css";

// Hooks
import { useSitesAction } from "@Hooks/useSitesActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "@Hooks/store";

//Redux
import {
  clearNotification,
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";

// Icons
import { AltArrowLeftIcon, CashRegisterStrokeIcon } from "@Models/icons";
import { CashRegister } from "@ReduxService/states/cashRegisterSlice";
import { MODIFY_LOUNGES_UUID } from "@Models/const/securityAccess";

// Interfaces

const SiteCashRegister = () => {
  const { idSede } = useParams();
  const { useGetCashRegisterDetails } = useSitesAction();
  const hasEditPermissions = validateModuleAccess(MODIFY_LOUNGES_UUID, false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const notificationState = useAppSelector((state) => state.notificationConfig);
  const cashRegistersState = useAppSelector((state) => state.listCashRegisters);
  const planConfig = useAppSelector((state) => state.planConfig);

  const currentCashregister: CashRegister = useGetCashRegisterDetails(
    state?.cashregister !== undefined ? state.cashregister : 0
  );

  let availableCashRegisterDetails =
    currentCashregister.idSaleBox > 0 || state?.cashregister === -1;

  const [currentSection, setCurrentSection] = useState(0);

  // Verificar si se alcanzó el límite de cajas
  const hasReachedCashRegisterLimit =
    cashRegistersState?.length >= planConfig.maxSalebox;

  const changeSection = (section) => {
    setCurrentSection(section);
  };

  const handleClickCashRegister = () => {
    return navigate(`/${idSede}`, {
      state: { cashregister: -1, viewAction: "create" },
    });
  };

  useEffect(() => {
    if (currentCashregister?.isDowngraded && availableCashRegisterDetails) {
      navigate(`/${idSede}`, { state: {} });
      dispatch(
        updateNotificationMessage(
          "Esta caja está deshabilitada debido a la cantidad de cajas disponibles en tu plan actual"
        )
      );
      dispatch(updateNotificationStatus(400));
    }
  }, [
    currentCashregister,
    availableCashRegisterDetails,
    navigate,
    idSede,
    dispatch,
  ]);

  return (
    <div className={style.siteCashRegisterContainer}>
      {availableCashRegisterDetails ? (
        <div className={style.leftNavigationMenu}>
          <div className={`${style.backNavigationSection} `}>
            <button
              className={style.backNavigationButtonContainer}
              onClick={() => navigate(`/${idSede}`, { state: {} })}
              tabIndex={currentSection === 0 ? 0 : -1}
            >
              <img src={AltArrowLeftIcon} alt="back" width={"10px"} />
            </button>
            <img
              src={CashRegisterStrokeIcon}
              alt="cashregister"
              width={"35px"}
            />
            <p>
              {availableCashRegisterDetails && currentCashregister?.saleBoxName
                ? currentCashregister?.saleBoxName.length > 30
                  ? `${currentCashregister?.saleBoxName.substring(0, 30)}...`
                  : currentCashregister?.saleBoxName
                : "Nueva caja"}
            </p>
          </div>
          <button
            className={`${style.navigationButton} ${
              currentSection === 0 ? style.activeSection : ""
            }`}
            onClick={() => changeSection(0)}
            tabIndex={currentSection === 0 ? 0 : -1}
          >
            <p>Información general</p>
          </button>
        </div>
      ) : (
        <ColumnNavigationGuide
          currentSection={currentSection}
          changeSection={changeSection}
        />
      )}

      <div className={style.viewContent}>
        {availableCashRegisterDetails ? (
          <CashRegisterDetails currentCashRegister={currentCashregister} />
        ) : currentSection === 0 ? (
          <div className={style.cashregisterListContainer}>
            <ButtonWithoutIcon
              textBttn={"Añadir caja"}
              handleClick={() =>
                validateModuleAccess(MODIFY_LOUNGES_UUID) &&
                handleClickCashRegister()
              }
              isPrimary
              width="fit-content"
              isDisabled={hasReachedCashRegisterLimit || !hasEditPermissions}
              tooltip={
                hasReachedCashRegisterLimit
                  ? "Has alcanzado el límite de cajas disponibles en tu plan"
                  : ""
              }
            />
            <SitesMenuContainer
              items={cashRegistersState ? cashRegistersState : []}
              type="cashregister"
            />
          </div>
        ) : currentSection === 2 ? (
          <CashRegisterMovementsTypes />
        ) : (
          <CashRegisterPaymentMethods />
        )}
      </div>
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </div>
  );
};

export default SiteCashRegister;
